import { ReactFragment, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, TextField } from "@material-ui/core";
import {
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  Icon,
  IconButton,
  Toolbar,
} from "@material-ui/core";
import { modalStyleText } from "../Profile/styles";
import api from "../../api";
import strapiAPI from "../../strapiAPI";
import { defaultTracks, TrackType } from "../EssayDialog/Fallbacks";
import Tracks from "../EssayDialog/Tracks/Tracks";

const useStyles = makeStyles({
  input: {
    color: "black"
  }
});

export const openModal = (name: string) => {
  //aktiver modal-vinduet her
  window.onclick = function (event) {
    var modal = document.getElementById(name) as HTMLElement;
    if (event.target.className == "modal" || event.target.className == "close") {
      modal.style.display = "none";
    }
  }
  var modal = document.getElementById(name) as HTMLElement;
  modal.style.display = "inline-block";

};

export const closeModal = (name: string) => {
  var modal = document.getElementById(name) as HTMLElement;
  modal.style.display = "none";
}

const Modal = props => {


  return (
    <div className="modal" id={props.id} style={{
      zIndex: 10
    }}>
      <div className="modal-content"  style={{
                overflowWrap: "break-word"
              }}>
        {props.children}
      </div>


    </div>
  );




}


export default Modal;