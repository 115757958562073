import { useEffect } from "react";
import { ThemeProvider, Typography } from "@material-ui/core";
import { innerFlexCenterWrapper } from "../../../styles/globalStyles";
import { useHistory, useParams } from "react-router-dom";
import api from "../../../api";
import { calendarTheme } from "../../../styles/theme";
import waait from "waait";

const ValidateUser = () => {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  useEffect(() => {
    const validateUser = async () => {
      const path = "/validate/" + token;
      try {
        await api.post(path);
        await waait(3000);
        history.push("/");
      } catch (error) {
        console.log(error.response.data.error.message);
      }
    };

    validateUser();
  });

  return (
    <div>
      <ThemeProvider theme={calendarTheme}>
        <div style={innerFlexCenterWrapper}>
          <Typography variant="h4" style={{ textAlign: "center" }}>
            THANK YOU!
          </Typography>
          <Typography variant="h5" style={{ textAlign: "center" }}>
            Your email has been verified. We will redirect you soon...
          </Typography>
        </div>
      </ThemeProvider>
    </div>
  );
};

export default ValidateUser;
