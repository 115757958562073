import {
  Card,
  CardContent,
  CardMedia,
  Direction,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { mdiArrowLeftCircleOutline, mdiArrowRightCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Dispatch, SetStateAction } from "react";
import { Colors } from "../../../constants";
import BottomDots from "../BottomDots/BottomDots";
import { ChallengeType } from "../Fallbacks";
import TrackCard from "../Tracks/TrackCard";

const useStyles = makeStyles({
  headerCenter: {
    textAlign: "center",
    padding: "2rem",
  },

});

interface Props {
  nextPageCallback: (direction: Direction) => void;
  challenges: ChallengeType[];
  setChallenge: Dispatch<SetStateAction<number>>;
  pickedChallenge: number;
}

const Challenges = (props: Props) => {
  const { headerCenter } = useStyles();
  const { nextPageCallback, challenges, pickedChallenge, setChallenge } = props;

  const disabled = () => pickedChallenge === undefined;
  return (
    <div
      style={{
        alignSelf: "center",
        display: "flex",
        justifyContent: "center",
        margin: "2em",
      }}>
      <Card>
        <Typography variant={"h4"} className={headerCenter} color={"primary"}>
          WHICH CHALLENGE HAVE YOU ANSWERED IN YOUR SUBMISSION?
        </Typography>

        <CardContent>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-around",
              marginRight: "1rem",
              marginLeft: "1rem",
            }}>
            {challenges.map((track, i) => {
              return (
                <TrackCard
                  text={"test1"}
                  key={i}
                  onPress={() => {
                    setChallenge(i);
                  }}
                  title={track.title}
                  marked={i === pickedChallenge}>
                  <CardMedia
                    component="img"
                    alt="Image Missing"
                    image={track.image ? track.image.formats.small.url : null}
                    title="CardImage"
                    className="ChallengeImage"
                  />
                </TrackCard>
              );
            })}
          </div>
        </CardContent>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginRight: "1rem",
            marginLeft: "1rem",
          }}>
          <IconButton
            onClick={() => {
              nextPageCallback("ltr");
            }}>
            <Icon
              path={mdiArrowLeftCircleOutline}
              color={Colors.orange}
              size={2}
            />
          </IconButton>
          <BottomDots marked={1}></BottomDots>
          <IconButton
            disabled={disabled()}
            onClick={() => {
              nextPageCallback("rtl");
            }}>
            <Icon
              path={mdiArrowRightCircleOutline}
              color={disabled() ? Colors.opaqueOrange : Colors.orange}
              size={2}
            />
          </IconButton>
        </div>
      </Card>
    </div>
  );
};

export default Challenges;
