import { useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { loginUser, useAuthDispatch, useAuthState } from "../../../Context";
import ReCaptcha, { CaptchaProps } from "../../../Recaptcha/Recaptcha";
import { Button, TextField, Typography } from "@material-ui/core";
import { termsStyle, textFieldStyle, textStyle, useStyles } from "./styles";
import "../../../index.css";
import { innerFlexCenterWrapper } from "../../../styles/globalStyles";
import { Colors } from "../../../constants";
import HorizontalLine from "../../../Components/HorizontalLine";
import logo from "../../../images/orange.png";
import { isMobile } from "react-device-detect";

const Login = (props: RouteComponentProps): JSX.Element => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [testBool, setTestBool] = useState<Boolean>(null);
  const { history } = props;
  const auth = useAuthState();

  const classes = useStyles();

  const dispatch = useAuthDispatch();
  const { loading, errorMessage } = useAuthState(); //read the values of loading and errorMessage from context

  const validateForm = () => {
    return email.length > 0 && password.length > 0;
  };

  const handleSubmit = async (execute: CaptchaProps["execute"]) => {
    const recaptchaToken = await execute();

    const payload = { email, password, recaptchaToken };

    try {
      const response = await loginUser(dispatch, payload);
      if (!response?.token) {
        return;
      }
      history.push("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ReCaptcha action="test">
      {(captcha) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(captcha.execute);
          }}>
          <div
            style={{
              display: isMobile ? "inline" : "flex",
              justifyContent: "center",
            }}>
            <div style={innerFlexCenterWrapper}>
              <img
                src={logo}
                style={{
                  marginBottom: "2em",
                  marginTop: "5rem",
                  maxWidth: isMobile ? "100%" : undefined,
                  alignSelf: "center",
                }}
              />
              <TextField
                style={textFieldStyle}
                value={email}
                label="Email"
                type="email"
                onChange={({ target }) => setEmail(target.value)}></TextField>
              <TextField
                style={textFieldStyle}
                label="Password"
                value={password}
                type="password"
                onChange={({ target }) =>
                  setPassword(target.value)
                }></TextField>
              <Button
                style={{ marginBottom: 30 }}
                //disabled={!validateForm() || loading || !captcha.isReady}
                disabled={false}
                className={classes.button}
                variant="contained"
                disableElevation
                fullWidth
                type="submit">
                LOGIN
              </Button>
              {/* <Typography
                variant={"caption"}
                style={{
                  alignSelf: "center",
                  marginBottom: "10px",
                }}>
                Closed for maintenance. Please check back in a few minutes
              </Typography> */}
              {errorMessage ? (
                <Typography
                  variant={"caption"}
                  style={{
                    alignSelf: "center",
                    marginBottom: "10px",
                  }}>
                  {auth.errorMessage}
                </Typography>
              ) : null}
              <div style={termsStyle}>
                <div style={textStyle}>
                  By continuing, you agree to accept our
                </div>
                <Link
                  style={textStyle}
                  to=""
                  onClick={() =>
                    window.open("https://aarhus-symposium.org/terms")
                  }>
                  Privacy Policy & Terms of Service
                </Link>
                <div style={{ marginTop: 20 }}>
                  <HorizontalLine
                    marginLeft={0}
                    marginRight={0}
                    color={Colors.opaceBlack}></HorizontalLine>
                  <Button
                    disabled={false}
                    onClick={() => {
                      history.push("/register");
                    }}
                    className={classes.root}>
                    SIGN UP
                  </Button>
                  <Button
                    onClick={() => {
                      history.push("/resetPassword");
                    }}
                    className={classes.root}>
                    FORGOT PASSWORD?
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </ReCaptcha>
  );
};

export default Login;
