import { useCallback, useState } from "react";
import _ from "lodash";
import "react-toastify/dist/ReactToastify.css";
import api, { setUserAsOrganiser } from "../../api";
import { Typography } from "@material-ui/core";
import { format } from "date-fns";
import Html5QrcodeScannerPlugin from "../../Components/Html5QRcodeScannerPlugin";
import { QrcodeSuccessCallback } from "html5-qrcode/esm/core";

interface Props {
  organiserName: string;
}

const QrScanner = (props: Props) => {
  let FUCKME = "123";
  let timeoutDate = new Date();
  const [timestampState, setTimestamp] = useState("");
  const [trackState, setTrack] = useState("");
  const [organiserState, setOrganiser] = useState("");
  const [textState, setText] = useState("");
  const [succes, setSucces] = useState(false);

  const postData = useCallback(async (qrString: string) => {
    //FOR SOME FUCKING REASON STATE DOES NOT WORK AS EXPECTED FOR THIS SHITTY THINGY FUCK.
    setUserAsOrganiser(props.organiserName);
    console.log("Organisor: " + props.organiserName);
    const isFocus = qrString.toLowerCase().includes("#");
    console.log("isFocus: " + isFocus);
    const id = isFocus ? qrString.split("#")[1] : qrString;
    console.log("id: " + id);
    if (FUCKME === qrString && new Date() < timeoutDate) return;
    FUCKME = qrString;
    let date = new Date();
    date.setSeconds(date.getSeconds() + 4);
    timeoutDate = date;
    try {
      const { data, status } = await api.post<{
        timestamp: Date;
        organiser: string;
        track: string;
        name: string;
      }>(isFocus ? "/focusCheckin" : "/checkin", {
        data: {
          id,
        },
      });

      const { organiser, timestamp: time, track, name } = data;

      if (status === 200) {
        setText(name);
        setSucces(true);
      }
      if (status === 201) {
        setText(`${name}`);
        setSucces(false);
      }

      setTimestamp(format(new Date(time), "dd/MM/yyyy HH:mm"));
      setOrganiser(organiser);
      if (!isFocus) setTrack(track);
    } catch (e) {
      setText("User not found");
      if (e.response.status === 403) {
        setText(e.response.data.message);
      } else if (e.response.status === 401) {
        setText("Organisor email not correct");
      }
      console.log(e);

      //This catches the 404 thrown in backend.
      setSucces(false);
      setTimestamp("");
      setOrganiser("");
      setTrack("");
    }
  }, []);

  const handleScan: QrcodeSuccessCallback = (decodedText: string) => {
    postData(decodedText);
  };

  const handleError = (err) => {
    //console.error(err);
  };

  const displayAlreadyScanned =
    textState === ""
      ? false
      : !succes &&
        textState !== "User not found" &&
        textState !== "Invalid Ticket: The user has no assigned track.";

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        textAlign: "center",
        backgroundColor: !textState ? "whitesmoke" : succes ? "green" : "red",
      }}>
      <div style={{ marginTop: "-15rem" }}>
        <Html5QrcodeScannerPlugin
          fps={30}
          qrbox={250}
          qrCodeSuccessCallback={handleScan}
          qrCodeErrorCallback={handleError}
        />
      </div>
      <div
        style={{
          backgroundColor: "whitesmoke",
          borderRadius: 20,
          margin: "1rem",
        }}>
        <Typography variant="h4">{trackState}</Typography>
        {!textState ? (
          <Typography variant="h6">{"Scanning"}</Typography>
        ) : (
          <Typography variant="h6">{textState}</Typography>
        )}
        {displayAlreadyScanned ? (
          <Typography variant="h6">{"ALREADY CHECKED IN"}</Typography>
        ) : null}
        <Typography variant="body1">{timestampState}</Typography>
        <Typography variant="body1">{organiserState}</Typography>
      </div>
    </div>
  );
};

export default QrScanner;
