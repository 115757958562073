import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles";
import { purple } from "@material-ui/core/colors";
import { Colors } from "../constants";
import "../index.css";

const TypographyOverride = {
  h1: {
    fontFamily: "futura-pt",
  },
  h2: {
    fontFamily: "futura-pt",
  },
  h3: {
    fontFamily: "futura-pt",
  },
  h4: {
    fontFamily: "futura-pt",
  },
  h5: {
    fontFamily: "futura-pt",
  },
  h6: {
    fontFamily: "futura-pt",
  },
  caption: {
    fontFamily: "futura-pt",
    fontSize: 12,
  },
  p: {
    fontFamily: "futura-pt",
  },
  body2: {
    fontFamily: "futura-pt",
  },
};

const underlineOverride = {
  "&:after": {
    borderBottom: `2px solid #FFFFFF`,
  },
  "&$focused:after": {
    borderBottomColor: `#FFFFFF`,
  },
  "&$error:after": {
    borderBottomColor: `#FFFFFF`,
  },
  "&:before": {
    borderBottom: `1px solid #FFFFFF`,
  },
  "&:hover:not($disabled):not($focused):not($error):before": {
    borderBottom: `2px solid #FFFFFF`,
  },
  "&$disabled:before": {
    borderBottom: `1px dotted #FFFFFF`,
  },
};

export const calendarTheme = createMuiTheme({
  palette: {
    primary: {
      // White
      main: Colors.orange,
    },
    secondary: {
      // Black
      main: Colors.white,
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        color: Colors.white,
      },
    },
    MuiTypography: TypographyOverride,
    MuiInput: {
      underline: underlineOverride,
    },
  },
});

export const appTheme = createMuiTheme({
  palette: {
    primary: {
      // White
      main: Colors.white,
    },
    secondary: {
      // Black
      main: Colors.orange,
    },
    text: {
      primary: "#3A8F4B",
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        color: Colors.black,
        fontFamily: "futura-pt",
      },
    },
    MuiButton: {
      root: {
        color: Colors.black,
        fontFamily: "futura-pt",
      },
    },
    MuiInput: {
      underline: underlineOverride,
    },

    MuiFormLabel: {
      root: {
        color: Colors.black,
        fontFamily: "futura-pt",
      },
    },
    MuiTypography: TypographyOverride,
    MuiDialog: {
      paper: {
        backgroundColor: "transparent",
      },
    },
  },
});

export const signUpTheme = createMuiTheme({
  palette: {
    primary: {
      // White
      main: Colors.white,
    },
    secondary: {
      // Black
      main: Colors.opaceBlack,
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        color: Colors.white,
      },
    },
    MuiInput: {
      underline: underlineOverride,
    },
    MuiFormLabel: {
      root: {
        color: Colors.white,
      },
    },
    MuiTypography: TypographyOverride,
  },
});
