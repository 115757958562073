import { useEffect, useState } from "react";
import api from "../../api";
import { VictoryBar, VictoryChart, VictoryTheme } from "victory";
import { Colors } from "../../constants";
import _ from "lodash";
import { Typography } from "@material-ui/core";

const UsersGraph = () => {
  const [users, setUsers] = useState([]);
  const [usersLength, setUsersLength] = useState(0);

  useEffect(() => {
    const getUserCount = async () => {
      const { data } = await api.get("/getUsersSignupDate");
      const grouped = _.groupBy(data, Math.floor);
      const graphData: { x: number; y: number }[] = [];
      Object.values(grouped).map((entry) =>
        graphData.push({ x: entry[0], y: entry.length })
      );
      setUsers(graphData);
      setUsersLength(data.length);
    };

    getUserCount();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <div
        style={{
          height: window.innerHeight * 0.9,
          width: window.innerHeight * 0.9,
        }}>
        <VictoryChart
          theme={VictoryTheme.material}
          domainPadding={{ x: 50, y: 50 }}>
          <VictoryBar
            style={{
              data: { stroke: Colors.orange },
              parent: { border: "1px solid #ccc" },
            }}
            data={users}
          />
        </VictoryChart>
      </div>
      <div>
        <Typography variant="h6" style={{ marginBottom: "1rem" }}>
          {"TOTAL AMOUNT OF USERS: " + usersLength}
        </Typography>
      </div>
    </div>
  );
};

export default UsersGraph;
