import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";
import { Colors } from "../../constants";
import { TimeLineData } from "./Timeline";

interface Props {
  timeLineData: TimeLineData;
}

const styles = makeStyles((theme) => ({
  paper: {
    padding: "20px 30px", //før 6px 16px
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  connectorColor: { backgroundColor: Colors.white },
}));
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const WebTimelineItem = (props: Props) => {
  const { timeLineData } = props;
  const { connectorColor } = styles();

  if (!timeLineData.date) return null;

  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Box mr={0}>
          <Typography variant="body2" color="secondary" font-family="futura-pt">
            {monthNames[
              new Date(timeLineData.date).getMonth()
            ].toLocaleUpperCase() +
              " " +
              new Date(timeLineData.date).getDate() +
              ", " +
              new Date(timeLineData.date).getFullYear()}
          </Typography>
        </Box>
        <Box mr={0}>
          <Typography variant="body2" color="secondary" font-family="futura-pt">
            {timeLineData.time?.slice(0, 5)}
          </Typography>
        </Box>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color="secondary" />
        <TimelineConnector className={connectorColor} />
      </TimelineSeparator>
      <TimelineContent>
        <a
          href={timeLineData.buttonLink}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", display: "block", fontFamily: "futura-pt" }} // Use display: "block" to maintain card dimensions
        >
          <Card>
            <CardActionArea disableRipple={true}>
              <CardMedia
                component="img"
                alt="Image Missing"
                height="10%"
                image={timeLineData.image?.formats?.medium?.url}
                title="CardImage"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2" font-family="futura-pt">
                  {timeLineData.title?.toLocaleUpperCase()}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" font-family="futura-pt">
                  {timeLineData.description}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                size="small"
                color="primary"
                font-family="futura-pt"
                href={timeLineData.buttonLink}
                target={"_blank"}>
                READ MORE
              </Button>
            </CardActions>
          </Card>
        </a>
      </TimelineContent>
    </TimelineItem>
  );
};

export default WebTimelineItem;
