import {
  Button,
  Card,
  CardContent,
  Container,
  Direction,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { mdiArrowLeftCircleOutline, mdiArrowRightCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Dispatch, SetStateAction, useState } from "react";
import { Colors } from "../../../constants";
import BottomDots from "../BottomDots/BottomDots";
import TrackCard from "../Tracks/TrackCard";

const useStyles = makeStyles({
  titleStyle: {
    textAlign: "center",
    margin: "1rem",
    fontWeight: "bold",
  },
  headerCenter: {
    textAlign: "center",
    margin: "1rem",
  },
  resetButton: {
    color: Colors.white,
    backgroundColor: Colors.orange,
    marginLeft: "1rem",
  },
});

interface Props {
  focus: boolean;
  setFocus: Dispatch<SetStateAction<boolean>>;
  nextPageCallback: (direction: Direction) => void;
}

const Focus = (props: Props) => {
  const { headerCenter, titleStyle } = useStyles();
  const { focus, setFocus, nextPageCallback } = props;

  const disabled = () => focus === null || focus === undefined;

  return (
    <div
      style={{
        alignSelf: "center",
        display: "flex",
        justifyContent: "center",
        marginTop: "2em",
        marginBottom: "2em",
      }}>
      <Card style={{ padding: "2rem" }}>
        <Typography variant={"h4"} className={headerCenter} color={"primary"}>
          ARE YOU INTERESTED IN PARTICIPATING
        </Typography>
        <Typography variant={"h4"} className={headerCenter} color={"primary"}>
          IN AARHUS SYMPOSIUM FOCUS 2024?
        </Typography>
        <Container maxWidth="sm">
          <Typography
            className={headerCenter}
            color={"primary"}
            style={{ fontFamily: "Book Antiqua" }}>
            {
              "Aarhus Symposium Focus is your chance to experience more of Aarhus Symposium. At Aarhus Symposium Focus, you will have a unique opportunity to experience one competent speaker addressing this year’s theme. "
            }
            <br></br>
            {
              "Are you uncertain of going alone? If you have registered as a group, you will also have the opportunity to experience the Aarhus Symposium Focus together. The event takes place on Monday, October 28, 2024."
            }
          </Typography>
          <video width="100%" controls src="../../../videos/Focus2023.mp4">
            Your browser does not support the video tag.
          </video>
        </Container>
        <CardContent>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-evenly",
              margin: "2em",
            }}>
            <TrackCard
              onPress={() => setFocus(false)}
              marked={focus === false}
              title=" "
              text="">
              <div style={{ paddingTop: "30%" }}>
                <Typography className={titleStyle} variant="h5" color="primary">
                  NO
                </Typography>
              </div>
            </TrackCard>

            <TrackCard
              onPress={() => setFocus(true)}
              marked={focus === true}
              title=" "
              text="">
              <div style={{ paddingTop: "30%" }}>
                <Typography className={titleStyle} variant="h5" color="primary">
                  YES
                </Typography>
              </div>
            </TrackCard>
          </div>
        </CardContent>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <IconButton
            onClick={() => {
              nextPageCallback("ltr");
            }}>
            <Icon
              path={mdiArrowLeftCircleOutline}
              color={Colors.orange}
              size={2}
            />
          </IconButton>
          <BottomDots marginLeft={"1rem"} marked={3}></BottomDots>
          <IconButton
            disabled={disabled()}
            onClick={() => {
              nextPageCallback("rtl");
            }}>
            <Icon
              path={mdiArrowRightCircleOutline}
              color={disabled() ? Colors.opaqueOrange : Colors.orange}
              size={2}
            />
          </IconButton>
        </div>
      </Card>
    </div>
  );
};

export default Focus;
