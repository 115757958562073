import { IUser } from "../interfaces";
import { AuthAction, AuthState } from "./interfaces";

const user: IUser = localStorage.getItem("currentUser")
  ? JSON.parse(localStorage.getItem("currentUser")!)
  : "";

export const initialState: AuthState = {
  email: user?.email ?? "",
  token: user?.token ?? "",
  expiresIn: "",
  loading: false,
  errorMessage: "",
};

export const AuthReducer = (
  initialState: AuthState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case "REQUEST_LOGIN":
      return {
        ...initialState,
        loading: true,
      };
    case "LOGIN_SUCCESS":
      return {
        ...initialState,
        email: action.payload?.email!,
        token: action.payload?.token!,
        loading: false,
        errorMessage: "",
      };
    case "LOGOUT":
      return {
        ...initialState,
        email: "",
        token: "",
      };

    case "LOGIN_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
