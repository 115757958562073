import { Direction, Slide } from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";
import api from "../../api";
import Challenges from "./Challenges/Challenges";
import EssayThankYou from "./EssayThankYou/EssayThankYou";
import EssayUpload from "./EssayUpload/EssayUpload";
import strapiAPI from "../../strapiAPI";
import {
  ADayAtType,
  ChallengeType,
  defaultADayAt,
  defaultChallenges,
  defaultTracks,
  TrackType,
} from "./Fallbacks";
import {
  CardMedia,
} from "@material-ui/core";
import Tracks from "./Tracks/Tracks";
import ADayAt from "./ADayAt/ADayAt";
import { AxiosResponse } from "axios";
import Focus from "./Focus/Focus";

interface EssayPayload {
  aDayAtPriority: number[];
  trackPriority: number[];
  challenge: number;
  transportation: boolean;
  focus: boolean;
  essayLanguage: "Danish" | "English";
}

const EssayDialog = () => {
  const [aDayAts, setADayAts] = useState<ADayAtType[]>(defaultADayAt);
  const [challenges, setChallenges] = useState(defaultChallenges);
  const [tracks, setTracks] = useState<TrackType[]>(defaultTracks);
  const [transportation, setTransportation] = useState(false);
  const [essayLanguage, setEssayLanguage] = useState<"Danish" | "English">(
    "Danish"
  );
  const [focus, setFocus] = useState<boolean>(null);
  const [page, setPage] = useState<number>(0);
  const [pickedChallenge, setPickedChallenge] = useState<number>(undefined);
  const [file, setFile] = useState<File | undefined>(undefined);

  useEffect(() => {
    const getDayAtAndChallenges = async () => {
      try {
        const aDayAts = await strapiAPI.get<ADayAtType[]>("/a-day-ats");
        const challenges = await strapiAPI.get<ChallengeType[]>("/challenges");
        const tracks = await strapiAPI.get<TrackType[]>("/tracks");
        const { data: prevData } = await api.get<EssayPayload>(
          "getTracksAndChallenge"
        );

        if (aDayAts && challenges && tracks && prevData) {
          console.log(prevData);
          setChallenges(challenges.data);
          setADayAts(
            aDayAts.data.map((a, i) => ({
              ...a,
              priority: prevData.aDayAtPriority?.[i] ?? 0,
            }))
          );
          setTracks(
            tracks.data.slice(0,4).map((a, i) => ({
              ...a,
              priority: prevData.trackPriority?.[i] ?? 0,
            }))
          );
          setFocus(prevData.focus);
          setPickedChallenge(prevData.challenge ?? undefined);
          setTransportation(prevData.transportation);
          setEssayLanguage(!!prevData.essayLanguage ? prevData.essayLanguage : "Danish");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getDayAtAndChallenges();
  }, []);

  const handleSubmit = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("essay", file, file.name);
      await api.post("/upload", formData);
    }

    const aDayAtPriority = aDayAts.map(({ priority }) => priority);
    const trackPriority = tracks.map(({ priority }) => priority);

    const { status } = await api.post<EssayPayload, AxiosResponse>(
      "/uploadTracksAndChallenge",
      {
        data: {
          trackPriority,
          challenge: pickedChallenge,
          aDayAtPriority,
          transportation,
          focus,
          essayLanguage,
        },
      }
    );

    if (status === 200) handleChange("rtl");
  };

  const resetTracks = () =>
    setTracks((tracks) => tracks.map((track) => ({ ...track, priority: 0 })));
  const resetADayAt = () =>
    setADayAts((aDayAts) =>
      aDayAts.map((aDayAt) => ({ ...aDayAt, priority: 0 }))
    );

  const handleChange = (direction: Direction) => {
    direction === "rtl"
      ? setPage((prev) => prev + 1)
      : setPage((prev) => prev - 1);
  };

  const renderTracksPage = () => (
    <Slide direction="right" in={page === 0} mountOnEnter unmountOnExit>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}>
        <Tracks
          resetValues={resetTracks}
          tracks={tracks}
          setPriority={setTracks}
          nextPageCallback={handleChange}
          text={"NOTE: These priorities are individual and will be used to assign you to a track. In case you are part of a group before September 24, 2024, the priorities picked by the group owner will be used instead."}
          title={"PLEASE SELECT TRACK PRIORITIES"}></Tracks>
      </div>
    </Slide>
  );

  const renderChallengesPage = () => (
    <Slide direction="left" in={page === 1} mountOnEnter unmountOnExit>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}>
        <Challenges
          pickedChallenge={pickedChallenge}
          setChallenge={setPickedChallenge}
          challenges={challenges}
          nextPageCallback={handleChange}></Challenges>
      </div>
    </Slide>
  );
  const renderADayAtPage = () => (
    <Slide direction="left" in={page === 2} mountOnEnter unmountOnExit>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}>
        <ADayAt
          resetValues={resetADayAt}
          aDayAts={aDayAts}
          setPriority={setADayAts}
          nextPageCallback={handleChange}></ADayAt>
      </div>
    </Slide>
  );
  const renderFocusPage = () => (
    <Slide direction="left" in={page === 3} mountOnEnter unmountOnExit>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}>
        <Focus
          focus={focus}
          setFocus={setFocus}
          nextPageCallback={handleChange}
        />
      </div>
    </Slide>
  );

  const renderUploadPage = () => (
    <Slide direction="left" in={page === 4} mountOnEnter unmountOnExit>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}>
        <EssayUpload
          transportation={transportation}
          setTransportation={setTransportation}
          setFile={setFile}
          file={file}
          essayLanguage={essayLanguage}
          setEssayLanguage={setEssayLanguage}
          nextPageCallback={handleChange}
          handleSubmit={handleSubmit}></EssayUpload>
      </div>
    </Slide>
  );

  const renderThankYouPage = () => (
    <Slide direction="left" in={page === 5} mountOnEnter unmountOnExit>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}>
        <EssayThankYou />
      </div>
    </Slide>
  );

  const renderPage = () => {
    return (
      <div>
        {page === 0 ? renderTracksPage() : null}
        {page === 1 ? renderChallengesPage() : null}
        {page === 2 ? renderADayAtPage() : null}
        {page === 3 ? renderFocusPage() : null}
        {page === 4 ? renderUploadPage() : null}
        {page === 5 ? renderThankYouPage() : null}
      </div>
    );
  };

  return renderPage();
};

export default EssayDialog;
