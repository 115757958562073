import { Direction, Slide, ThemeProvider, Toolbar } from "@material-ui/core";
import * as fs from "fs";
import { CSSProperties, Fragment, useState } from "react";
import { signUpTheme } from "../../../styles/theme";
import Page1 from "./Page1/Page1";
import Page2 from "./Page2/Page2";
import Page3 from "./Page3/Page3";
import Page4 from "./Page4/Page4";
import { useStyles } from "./styles";
import bgImage from "../../../images/Background2024.png";
import logo from "../../../images/logo.png";
import { DropDownOption, RegisterPayload } from "../../../Context/interfaces";

const Register = () => {
  const [page, setPage] = useState<number>(0);
  const [slideDirection, setSlideDirection] = useState<"left" | "right">(
    "right"
  );
  const [userData, setUserData] = useState<RegisterPayload>({
    vegetarian: false,
    studyEnd: null,
    study: "",
    institution: "",
    postalCode: "",
    phone: "",
    name: "",
    nationality: "",
    email: "",
    password: "",
    semester: "",
    gender: undefined,
    studyTitle: undefined,
    exchangeStudent: false,
    permitCompanySharing: false,
    glutenIntolerant: false,
    vegan: false,
  });

  const classes = useStyles();

  const onClick = (direction: Direction) => {
    if (direction === "rtl") {
      setSlideDirection("left");
      setPage((prev) => prev + 1);
    } else {
      setSlideDirection("right");
      setPage((prev) => prev - 1);
    }
  };

  const pageStyle: CSSProperties = {
    display: "flex",
    justifyContent: "center",
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    height: "100%",
    padding: "1em",
  };

  const transitionProps = {
    timeout: {
      enter: 150, // Increase the enter transition duration for a smoother slide
      exit: 0,
    },
  };

  const renderPage1 = () => (
    <Slide
      direction={slideDirection}
      in={page === 0}
      mountOnEnter
      unmountOnExit
      {...transitionProps}>
      <div style={pageStyle}>
        <Page1
          setUserDataCallback={setUserData}
          userData={userData}
          onClick={onClick}
        />
      </div>
    </Slide>
  );

  const renderPage2 = () => (
    <Slide
      direction={slideDirection}
      in={page === 1}
      mountOnEnter
      unmountOnExit
      {...transitionProps}>
      <div style={pageStyle}>
        <Page2
          setUserDataCallback={setUserData}
          userData={userData}
          onClick={onClick}
        />
      </div>
    </Slide>
  );

  const renderPage3 = () => (
    <Slide
      direction={slideDirection}
      in={page === 2}
      mountOnEnter
      unmountOnExit
      {...transitionProps}>
      <div style={pageStyle}>
        <Page3
          setUserDataCallback={setUserData}
          userData={userData}
          onClick={onClick}
        />
      </div>
    </Slide>
  );

  const renderPage4 = () => (
    <Slide
      direction={slideDirection}
      in={page === 3}
      mountOnEnter
      unmountOnExit
      {...transitionProps}>
      <div style={pageStyle}>
        <Page4 />
      </div>
    </Slide>
  );

  return (
    <ThemeProvider theme={signUpTheme}>
      <Toolbar className={classes.colorDefault}>
        <a href={"/"}>
          <img
            src={logo}
            style={{
              width: "7em",
              marginBottom: ".8em",
              marginTop: ".8em",
              alignSelf: "center",
            }}
          />
        </a>
      </Toolbar>
      <div style={{ backgroundColor: "#e88542" }}>
        {renderPage1()}
        {renderPage2()}
        {renderPage3()}
        {renderPage4()}
      </div>
    </ThemeProvider>
  );
};

export default Register;
