export const institutions = [
  'Aalborg Universitet (AAU)',
  'Aarhus Universitet (AU)',
  'Copenhagen Business School (CBS)',
  'Danmarks Tekniske Universitet (DTU)',
  'IT-Universitet i København',
  'Københavns Universitet (KU)',
  'Roskilde Universitet (RUC)',
  'Syddansk Universitet (SDU)',
  'VIA University College',
  'Erhvervsakademi Aarhus (EAAA)',
];
