import { Dispatch, SetStateAction } from "react";
import {
  Button,
  Direction,
  MenuItem,
  TextField,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { stepStyle, headlineStyle, useStyles, selectStyle } from "../styles";
import IOSSwitch from "../../../../Components/iOSSwitch";
import { DatePicker } from "@material-ui/pickers";
import { calendarTheme } from "../../../../styles/theme";
import { isPast } from "date-fns";
import { innerFlexCenterWrapper } from "../../../../styles/globalStyles";
import {
  DropDownOption,
  GroupedDropDownOptions,
  RegisterPayload,
  StudyTitle,
  otherOption,
} from "../../../../Context/interfaces";
import Select from "react-select";
import React from "react";
import {
  getInstitutionOptions,
  getSelectedInsitution,
} from "../../../../data/institutions/institutionDataHelper";
import EducationInputComponent, {
  getEducationOptions,
  getSelectedEducation,
} from "../../../../data/educations/educationDataHelper";

interface Props {
  setUserDataCallback: Dispatch<SetStateAction<RegisterPayload>>;
  userData: RegisterPayload;
  onClick: (dir: Direction) => void;
}

const Page1 = (props: Props): JSX.Element => {
  const { setUserDataCallback, userData, onClick } = props;

  const institutionOptions: DropDownOption[] = getInstitutionOptions();
  const selectedInstitution: DropDownOption = getSelectedInsitution(
    userData.institution,
    institutionOptions
  );

  const groupedEducationOptions: GroupedDropDownOptions[] =
    getEducationOptions();
  const selectedEducation: DropDownOption = getSelectedEducation(
    userData.study,
    groupedEducationOptions[1].options
  );

  const [selectedInstitutionOption, setSelectedInstitutionOption] =
    React.useState<DropDownOption | null>(selectedInstitution);
  const [selectedEducationOption, setSelecetedEducationOption] =
    React.useState<DropDownOption | null>(selectedEducation);

  const {
    study,
    studyEnd,
    institution,
    studyTitle,
    semester,
    exchangeStudent,
    permitCompanySharing
  } = userData;

  const buttonZeroDisabled = !(
    study &&
    studyEnd !== null &&
    !isPast(studyEnd) &&
    institution &&
    studyTitle &&
    semester
  );

  const { input, button } = useStyles();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onClick("rtl");
      }}>
      <div style={innerFlexCenterWrapper}>
        <Typography variant="h6" style={stepStyle}>
          STEP 1 OF 3
        </Typography>

        <Typography variant="h6" style={headlineStyle}>
          WHAT IS YOUR EDUCATIONAL INSTITUTION?
        </Typography>

        <Select
          placeholder="Institution"
          value={selectedInstitutionOption}
          styles={selectStyle}
          onChange={(e) => {
            setSelectedInstitutionOption(e);
            let institution = e.value;
            if (e === otherOption) {
              institution = "";
            }
            setUserDataCallback((prev) => ({
              ...prev,
              institution: institution,
            }));
          }}
          options={institutionOptions}
          isSearchable={true}
        />
        {selectedInstitutionOption &&
          selectedInstitutionOption.value === otherOption.value && (
            <TextField
              style={{ marginTop: "1rem" }}
              placeholder="Please provide the name of your educational institution"
              value={userData.institution ? userData.institution : ""}
              onChange={({ target }) =>
                setUserDataCallback((prev) => ({
                  ...prev,
                  institution: target.value,
                }))
              }
            />
          )}

        <Typography variant="h6" style={headlineStyle}>
          WHAT ARE YOU STUDYING?
        </Typography>

        <EducationInputComponent
          selectedInstitutionOption={selectedInstitutionOption}
          selectedEducationOption={selectedEducationOption}
          groupedEducationOptions={groupedEducationOptions}
          payloadStudy={userData.study}
          setUserDataCallback={setUserDataCallback}
          setSelecetedEducationOption={setSelecetedEducationOption}
        />

        <Typography variant="h6" style={headlineStyle}>
          HOW FAR ARE YOU IN YOUR STUDIES?
        </Typography>

        <TextField
          select
          className={input}
          value={studyTitle || ""}
          onChange={({ target }) =>
            setUserDataCallback((prev) => ({
              ...prev,
              studyTitle: target.value as StudyTitle,
            }))
          }>
          {["Bachelor", "Master", "PhD"].map((key) => (
            <MenuItem key={key} value={key}>
              {key}
            </MenuItem>
          ))}
        </TextField>

        <Typography variant="h6" style={headlineStyle}>
          WHICH SEMESTER ARE YOU CURRENTLY IN?
        </Typography>

        <TextField
          placeholder={"Please provide your semester number"}
          value={semester || ""}
          select
          className={input}
          onChange={({ target }) =>
            setUserDataCallback((prev) => ({
              ...prev,
              semester: target.value,
            }))
          }>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((key) => (
            <MenuItem key={key} value={key}>
              {key + ". semester"}
            </MenuItem>
          ))}
        </TextField>

        <Typography variant="h6" style={headlineStyle}>
          WHEN WILL YOU COMPLETE YOUR STUDIES?
        </Typography>
        <ThemeProvider theme={calendarTheme}>
          <DatePicker
            disablePast
            emptyLabel={"Click to select"}
            style={{ marginBottom: 20 }}
            views={["year", "month"]}
            color="secondary"
            value={studyEnd}
            onChange={(date) =>
              setUserDataCallback((prev) => ({
                ...prev,
                studyEnd: date,
              }))
            }
          />
        </ThemeProvider>
        {isPast(studyEnd) ? (
          <p style={{ color: "red" }}>
            You have to be an active student to participate
          </p>
        ) : null}

        <Typography
          variant="h6"
          style={{
            color: "#FFF",
            paddingTop: "1rem",
            paddingBottom: "2rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          ARE YOU AN EXHANGE STUDENT?
          <IOSSwitch
            checked={exchangeStudent}
            onChange={({ target }) => {
              setUserDataCallback((prev) => ({
                ...prev,
                exchangeStudent: target.checked,
              }));
            }}
          />
        </Typography>

        {/* <Typography
          variant="h6"
          style={{
            color: "#FFF",
            paddingTop: "1rem",
            paddingBottom: "2rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          ALLOW COMPANIES TO CONTACT YOU?
          <IOSSwitch
            checked={permitCompanySharing}
            onChange={({ target }) => {
              setUserDataCallback((prev) => ({
                ...prev,
                permitCompanySharing: target.checked,
              }));
            }}
          />
        </Typography> */}

        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            paddingTop: "0.5rem",
          }}>
          <Button
            className={button}
            variant="contained"
            color="primary"
            disableElevation
            disabled={buttonZeroDisabled}
            type="submit">
            NEXT
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Page1;
