import { Colors } from "../constants";

interface Props {
  marginLeft?: number;
  marginRight?: number;
  color: string;
}

const HorizontalLine = (props: Props) => {
  const { marginRight, color, marginLeft } = props;

  return (
    <div
      style={{
        borderTop: `1px solid  ${color}`,
        marginLeft: marginLeft !== undefined ? marginLeft : 20,
        marginRight: marginRight !== undefined ? marginRight : 20,
        marginBottom: 10,
      }}></div>
  );
};

export default HorizontalLine;
