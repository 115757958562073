import { isMobile } from "react-device-detect";
import { innerFlexCenterWrapper } from "../../styles/globalStyles";
import logo from "../../images/orange.png";
import { Button, TextField } from "@material-ui/core";
import { textFieldStyle, useStyles } from "../Auth/Login/styles";
import { Dispatch, SetStateAction, useState } from "react";

interface Props {
  setLoggedIn: Dispatch<SetStateAction<boolean>>;
}

const PasswordPage = (props: Props) => {
  const handleSubmit = async () => {
    if (email === "AS2023" && password === "CarLlamaTaxesMinutes")
      props.setLoggedIn(true);
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const classes = useStyles();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}>
        <div style={innerFlexCenterWrapper}>
          <img
            src={logo}
            style={{
              marginBottom: "2em",
              maxWidth: isMobile ? "100%" : undefined,
              alignSelf: "center",
            }}
          />
          <TextField
            style={textFieldStyle}
            value={email}
            label="Login"
            onChange={({ target }) => setEmail(target.value)}></TextField>
          <TextField
            style={textFieldStyle}
            label="Password"
            value={password}
            type="password"
            onChange={({ target }) => setPassword(target.value)}></TextField>
          <Button
            style={{ marginBottom: 30 }}
            className={classes.button}
            variant="contained"
            disableElevation
            fullWidth
            type="submit">
            LOGIN
          </Button>
        </div>
      </div>
    </form>
  );
};

export default PasswordPage;
