export const About = (): JSX.Element => {
  return (
    <div
      style={{
        flex: 1,
        textAlign: "center",
        backgroundColor: "red",
        height: "100%",
      }}>
      hej
    </div>
  );
};
