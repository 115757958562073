import axios from "axios";
import { IUser } from "./interfaces";
import { setupCache } from "axios-cache-adapter";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8080/api"
      : "https://www.signup.asymp.org/api",
  adapter: cache.adapter,
});
// Request interceptor for API calls
api.interceptors.request.use(
  async (config) => {
    const user: IUser = localStorage.getItem("currentUser")
      ? JSON.parse(localStorage.getItem("currentUser")!)
      : "";
    if (user)
      config.headers = {
        ...config.headers,
        Authorization: user.token,
      };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const setUserAsOrganiser = (email: string) => {
  api.interceptors.request.use(
    async (config) => {
      config.headers = {
        Organisermail: email,
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
};

export default api;
