import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  CardMedia,
  makeStyles,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import React from "react";
import { isMobile } from "react-device-detect";
import { Colors } from "../../../constants";

import { calendarTheme } from "../../../styles/theme";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    maxWidth: 380,
    minHeight: 300,
  },
  titleStyle: {
    textAlign: "center",
    margin: "1rem",
    fontWeight: "bold",
  },
  underStyle: {
    textAlign: "center",
    marginBottom: 12,
    fontWeight: "bold",
  },
  textStyle: {
    whiteSpace: "pre-line",
  },
});

interface Props {
  children?: any;
  image?: string;
  title: string;
  subTitle?: string;
  text: string;
  number?: number;
  onPress: () => void;
  subText?: string;
  marked?: boolean;
}

const TrackCard = (props: Props) => {
  const { text, title, number, onPress, subText, marked, subTitle } = props;
  const { root, titleStyle, underStyle, textStyle } = useStyles();

  const mobileText = text.replace(/(\r\n|\n|\r)/gm, "");

  return (
    <div style={{ margin: "1rem" }}>
      <ButtonBase
        onClick={() => {
          onPress();
        }}>
        <Card
          className={root}
          variant="outlined"
          style={{
            backgroundColor: marked ? Colors.opaqueOrange : Colors.white,
          }}>
          <Typography className={titleStyle} variant="h5" color="primary">
            <p style={{
              fontSize: title.length > 22 ? "20px": ""
            }}>{title}</p>
          </Typography>
          {/* {props.image ? (
            <CardMedia
              component="img"
              alt="Image Missing"
              image={props.image}
              title="CardImage"
            />
          ) : null} */}
          <CardContent>
            {props.children}
          </CardContent>
        </Card>
      </ButtonBase>
    </div>
  );
};

export default TrackCard;