import { useCallback, useContext, useEffect, useState } from "react";
import api from "../../api";
import { VictoryBar, VictoryChart, VictoryTheme } from "victory";
import { Colors } from "../../constants";
import _ from "lodash";
import { Typography } from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";
import { SocketContext } from "../..";

const EssayGraph = () => {
  const [essays, setEssays] = useState([]);
  const [essayLength, setEssayLength] = useState(0);

  const socket = useContext(SocketContext);

  const getEssayCount = async () => {
    const { data } = await api.get("/getEssayAmount");

    const grouped = _.groupBy(data, Math.floor);

    const graphData: { x: number; y: number }[] = [];

    Object.values(grouped).map((entry) =>
      graphData.push({ x: entry[0], y: entry.length })
    );

    setEssays(graphData);
    setEssayLength(data.length);
  };

  const handleEssay = useCallback((data) => {
    setEssayLength(data);
  }, []);

  useEffect(() => {
    socket.on("newEssay", handleEssay);
  }, []);

  useEffect(() => {
    getEssayCount();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          height: window.innerHeight * 0.9,
          width: window.innerHeight * 0.9,
        }}
      >
        <VictoryChart
          theme={VictoryTheme.material}
          domainPadding={{ x: 50, y: 50 }}
        >
          <VictoryBar
            style={{
              data: { stroke: Colors.orange },
              parent: { border: "1px solid #ccc" },
            }}
            data={essays}
          />
        </VictoryChart>
      </div>

      <div>
        <Typography variant="h6" style={{ marginBottom: "1rem" }}>
          {"TOTAL AMOUNT OF ESSAYS: " + essayLength}
        </Typography>
      </div>
    </div>
  );
};

export default EssayGraph;
