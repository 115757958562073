import { IUser } from "../interfaces";

export interface AuthAction {
  payload?: AuthState;
  type: string;
  error?: string;
}

export interface AuthState extends IUser {
  loading: boolean;
  errorMessage?: string;
}

export interface LoginPayload {
  email: string;
  password: string;
  recaptchaToken?: string;
}

export interface RegisterPayload extends LoginPayload {
  study: string;
  institution: string;
  studyEnd: Date | null;
  vegetarian: boolean;
  glutenIntolerant: boolean;
  vegan: boolean;
  name: string;
  phone: string;
  postalCode: string;
  nationality: string;
  email: string;
  password: string;
  semester: string;
  gender: Gender;
  studyTitle: StudyTitle;
  exchangeStudent: boolean;
  permitCompanySharing: boolean;
}

export interface DropDownOption {
  label: string
  value: string
}

export const otherOption: DropDownOption = {
  value: "other andet none",
  label: "Other (please specify underneath)",
};

export interface GroupedDropDownOptions {
  label: string
  options: DropDownOption[]
}

export type EssayLanguage = "Danish" | "English";

export type StudyTitle = "PhD" | "Bachelor" | "Graduate";

export type Gender = "Man" | "Woman" | "Other";

//   essayLanguage?: EssayLanguage;
