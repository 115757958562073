import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Direction,
  IconButton,
  makeStyles,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { Dispatch, SetStateAction, useState } from "react";
import TrackCard from "./TrackCard";
import Icon from "@mdi/react";
import { mdiArrowRightCircleOutline } from "@mdi/js";
import { Colors } from "../../../constants";
import BottomDots from "../BottomDots/BottomDots";
import { calendarTheme } from "../../../styles/theme";
import { TrackType } from "../Fallbacks";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles({
  headerCenter: {
    textAlign: "center",
    paddingTop: "4rem",
    paddingBottom: "2rem",
  },
  resetButton: {
    color: Colors.white,
    backgroundColor: Colors.orange,
    marginLeft: "1rem",
  },
  root: {
    minWidth: 275,
    maxWidth: 300,
    minHeight: 300,
  },
  titleStyle: {
    textAlign: "center",
    margin: "1rem",
    fontWeight: "bold",
  },
  underStyle: {
    textAlign: "center",
    marginBottom: 12,
    fontWeight: "bold",
  },
  textStyle: {
    whiteSpace: "pre-line",
  },
});
interface Props {
  children?: React.ReactNode;
  nextPageCallback: (direction: Direction) => void;
  tracks: TrackType[];
  setPriority: Dispatch<SetStateAction<TrackType[]>>;
  resetValues: () => void;
  handleCreateGroup?: () => void;
  text: string;
  title: string;
  focusChoice?: boolean | null;
}

const getImageStringFromTrack = (track: TrackType, image: string): string => {
  let imageObj = track[image];
  if (imageObj === undefined){
    return "";
  }
  
  if (imageObj['formats']['medium'] !== undefined){
    return imageObj['formats']['medium']['url'];
  }
  if (imageObj['formats']['small'] !== undefined){
    return imageObj['formats']['small']['url'];
  }
  if (imageObj['formats']['thumbnail'] !== undefined){
    return imageObj['formats']['thumbnail']['url'];
  }
  return "";
}

const Tracks = (props: Props) => {
  const { headerCenter, textStyle, titleStyle } = useStyles();
  const { nextPageCallback, tracks, setPriority, resetValues, handleCreateGroup } = props;
  const [priorityToGive, setPriorityToGive] = useState<number>(1);

  const setPrio = (cardNumber: number) => {
    const newArray = tracks;

    let prio = tracks[cardNumber].priority;
    if (cardNumber > 4) return;
    if (prio !== 0 && prio !== undefined) return;
    newArray[cardNumber].priority = priorityToGive;
    setPriorityToGive(priorityToGive + 1);
    setPriority([...newArray]);
  };

const disabled = () => !tracks.every((current) => current.priority !== 0 && current.priority !== undefined) || props.focusChoice === null;

  return (
    <div
      style={{
        alignSelf: "center",
        display: "flex",
        justifyContent: "center",
        marginTop: "1em",
        marginBottom: "3em",
        maxWidth: "100%"
      }}>
      <Card>
        <Typography variant={"h4"} className={headerCenter} color={"primary"}>
          {props.title}
        </Typography>
        <Container maxWidth="sm">
          <Typography
            className={headerCenter}
            color={"primary"}
            style={{ fontFamily: "Book Antiqua" }}>
            All participants at Aarhus Symposium will be placed on a track,
            each track consisting of four speakers.
            Please let us know which track you find the most exciting,
            by prioritizing them from 1-4.
            We will do our best to make sure all participants are placed on their first priority. <br></br>
            {props.text}
          </Typography>
        </Container>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
          }}>
          <ThemeProvider theme={calendarTheme}>
            <Button
              onClick={() => {
                resetValues();
                setPriorityToGive(1);
              }}
              variant="contained"
              color="primary"
              size="large">
              <Typography variant="button" color="secondary">
                Reset selection
              </Typography>
            </Button>
          </ThemeProvider>
        </Box>

        <CardContent>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-around",
            }}>
            {tracks.map((track, i) => {
              return (
                <TrackCard
                  key={i}
                  onPress={() => setPrio(i)}
                  title={track.title}
                  subText={track.subText}
                  text={track.description}
                  number={track.priority}>
                  <ThemeProvider theme={calendarTheme}>

                    <div style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "baseline",
                    }}>
                      <img src={getImageStringFromTrack(track, 'image_1')} className="TrackImage" style={
                        {
                          width: "380px"
                        }
                      }>
                      </img>
                     
                    </div>
                  </ThemeProvider>
                  <Box
                    height="6em"
                    display="flex"
                    alignItems="center"
                    justifyContent="center">
                    <Typography
                      style={{ fontWeight: "bold" }}
                      variant={track.priority == 0 ? (isMobile ? "h5" : "h4") : "h2"}
                      color="primary">
                      {track.priority == 0 ? "Click to select" : track.priority}
                    </Typography>
                  </Box>
                </TrackCard>
              );
            })}
          </div>
          {handleCreateGroup !== undefined ?
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              style={{
                width: "200px"
              }}
              onClick={() => {
                resetValues();
                setPriorityToGive(1);
                if (handleCreateGroup !== undefined) {
                  handleCreateGroup();
                }
              }}
              variant="contained"
              disabled={props.focusChoice === null}
              color="primary"
              size="large">
              <Typography variant="button" color="secondary">
                I don't have any priority
              </Typography>
            </Button>
            <Button
            variant="contained"
            disabled={disabled()}
            color="primary"
            onClick={() => {
              handleCreateGroup();
            }
            }
            size="large"
            style={{
              marginLeft: "1rem",
              width: "200px"
            }}

          >
            <Typography variant="button" color="secondary">
              Create Group
            </Typography>
          </Button>
          </div>
            :
            <></>}
          {/* {handleCreateGroup !== undefined ?
          <Button
          variant="contained"
          disabled={disabled()}
          color="primary"
          onClick={() => {
            handleCreateGroup();
          }
          }
          size="large"

        >
          <Typography variant="button" color="secondary">
            Create Group
          </Typography>
        </Button> : <></>
        } */}
          {nextPageCallback !== null ?
            <Button
              style={{
                marginLeft: "1rem",
              }}
              onClick={() => {
                resetValues();
                setPriorityToGive(1);
                if (nextPageCallback !== null) {
                  nextPageCallback("rtl");
                }
              }}
              variant="contained"
              color="primary"
              size="large">
              <Typography variant="button" color="secondary">
                I don't have any priority
              </Typography>
            </Button> :
            <></>}
        </CardContent>
        {nextPageCallback !== null ?
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <Icon
              path={mdiArrowRightCircleOutline}
              color={Colors.white}
              size={2}
            />
            <BottomDots marginLeft={"1rem"} marked={0}></BottomDots>
            <IconButton
              disabled={disabled()}
              onClick={() => {
                nextPageCallback("rtl");
              }}>
              <Icon
                path={mdiArrowRightCircleOutline}
                color={disabled() ? Colors.opaqueOrange : Colors.orange}
                size={2}
              />
            </IconButton>
          </div>
          : <></>}
      </Card>
    </div>
  );
};

export default Tracks;
