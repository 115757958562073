import {
  createStyles,
  Switch,
  SwitchClassKey,
  SwitchProps,
  Theme,
  withStyles,
} from "@material-ui/core";
import { Colors } from "../constants";

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 61,
      height: 25,
      padding: 0,
      margin: theme.spacing(1),
      overflow: "visible",
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(31px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: Colors.orange,
          opacity: 1,
        },
      },
      "&$focusVisible $thumb": {
        color: Colors.opaceBlack,
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 20,
      height: 20,
      margin: 4,
    },
    track: {
      borderRadius: 61 / 2,
      border: `3px solid #fff`,
      backgroundColor: theme.palette.grey[400],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default IOSSwitch;
