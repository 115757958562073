import { Button, Direction, TextField, Typography } from "@material-ui/core";
import { AxiosResponse } from "axios";
import {
  Dispatch,
  SetStateAction,
  useState,
  FormEvent,
  useContext,
} from "react";
import api from "../../../../api";
import { RegisterPayload } from "../../../../Context/interfaces";
import { innerFlexCenterWrapper } from "../../../../styles/globalStyles";
import { headlineStyle, stepStyle, useStyles } from "../styles";
import { isMobile } from "react-device-detect";
import { termsStyle } from "../../Login/styles";
import { Colors } from "../../../../constants";
import { Link } from "react-router-dom";

interface Props {
  setUserDataCallback: Dispatch<SetStateAction<RegisterPayload>>;
  userData: RegisterPayload;
  onClick: (dir: Direction) => void;
}

const Page3 = (props: Props) => {
  const { setUserDataCallback, userData, onClick } = props;
  const {
    email,
    password,
    nationality,
    name,
    vegetarian,
    glutenIntolerant,
    phone,
    studyEnd,
    study,
    postalCode,
    institution,
    gender,
    semester,
    studyTitle,
    exchangeStudent,
    permitCompanySharing,
  } = userData;
  const { button } = useStyles();

  const [error, setError] = useState("");

  const validateForm = () => {
    return email.length > 0 && password.length > 0;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const data = await api.post<RegisterPayload, AxiosResponse>("/register", {
        data: {
          email,
          password,
          name,
          phone,
          nationality,
          studyEnd,
          study,
          vegetarian,
          postalCode,
          institution,
          gender,
          semester,
          studyTitle,
          exchangeStudent,
          permitCompanySharing,
          glutenIntolerant,
        },
      });
      if (data.status === 200) {
        onClick("rtl");
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 400)
        setError(error.response.data?.error?.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={innerFlexCenterWrapper}>
        <Typography variant="h6" style={stepStyle}>
          STEP 3 OF 3
        </Typography>
        <Typography variant="h4" style={headlineStyle}>
          CREATE YOUR ACCOUNT
        </Typography>
        <TextField
          style={{ display: "flex", marginBottom: 20 }}
          value={email}
          label="Email"
          onChange={({ target }) =>
            setUserDataCallback((prev) => ({
              ...prev,
              email: target.value,
            }))
          }></TextField>
        <TextField
          style={{ display: "flex", marginBottom: 20 }}
          label="Password"
          value={password}
          type="password"
          onFocus={() => setError("")}
          onChange={({ target }) =>
            setUserDataCallback((prev) => ({
              ...prev,
              password: target.value,
            }))
          }></TextField>
        <Typography variant="caption" style={{ textAlign: "center" }}>
          {error}
        </Typography>
        <div style={termsStyle}>
          <div style={{ color: Colors.white, fontSize: 13 }}>
            By continuing, you agree to accept our
          </div>
          <Link
            style={{ fontSize: 13, color: Colors.white }}
            to=""
            onClick={() => window.open("https://aarhus-symposium.org/terms")}>
            Privacy Policy & Terms of Service
          </Link>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
            marginRight: isMobile ? 20 : 0,
          }}>
          <Button
            className={button}
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => onClick("ltr")}>
            PREVIOUS
          </Button>
          <div style={{ padding: 10 }}></div>
          <Button
            disabled={false}
            className={button}
            variant="contained"
            color="primary"
            disableElevation
            type="submit">
            SIGN UP
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Page3;
