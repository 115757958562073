import { Button, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import api from "../../../api";
import HorizontalLine from "../../../Components/HorizontalLine";
import { Colors } from "../../../constants";
import { innerFlexCenterWrapper } from "../../../styles/globalStyles";
import { textFieldStyle, useStyles } from "../Login/styles";

export const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async () => {
    try {
      const url = await api.post("/recover", { data: { email: email } });

      if (url.status === 200) {
        setMessage("Email sent successfully");
      }
    } catch (error) {
      error.response.status === 401
        ? setMessage("Email sent successfully, remember to check your spam")
        : setMessage("Somethig went wrong, please try again later...");
    }
  };
  const history = useHistory();

  const classes = useStyles();

  return (
    <div
      style={{
        display: isMobile ? "inline" : "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <div style={innerFlexCenterWrapper}>
        <Typography
          style={{ marginBottom: 40, alignSelf: "center" }}
          variant="h5">
          CAN'T LOG IN?
        </Typography>
        <Typography variant="caption">
          If your email is associated with an account, we'll send a recovery
          link to you
        </Typography>
        <TextField
          style={{ display: "flex", marginBottom: 20 }}
          value={email}
          label="Email"
          onChange={({ target }) => setEmail(target.value)}></TextField>
        <Button
          style={textFieldStyle}
          disabled={!email}
          className={classes.button}
          variant="contained"
          disableElevation
          onClick={() => handleSubmit()}
          fullWidth>
          REQUEST NEW PASSWORD
        </Button>
        <HorizontalLine color={Colors.opaceBlack}></HorizontalLine>
        {message ? (
          <Typography
            variant={"caption"}
            style={{
              alignSelf: "center",
              marginBottom: "10px",
            }}>
            {message}
          </Typography>
        ) : null}
        <div style={{ justifyContent: "center", display: "flex" }}>
          <Button
            onClick={() => {
              history.push("/login");
            }}
            className={classes.root}>
            Return to login
          </Button>
        </div>
      </div>
    </div>
  );
};
