import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { mdiArrowLeftCircleOutline, mdiArrowRightCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { useState } from "react";
import { useEffect } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import api from "../../api";
import { Colors } from "../../constants";
import { pdfjs } from "react-pdf";
import { useParams } from "react-router";
import { Rating } from "@material-ui/lab";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const EssayReviewExternal = () => {
  const essayBaseUrl = process.env.REACT_APP_DIGITALOCEANBASE;
  const queryParams = new URLSearchParams(location.search);
  const emailFromQuery = queryParams.get("email")
    ? queryParams.get("email")
    : "";

  const [url, setUrl] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { id } = useParams<{ id: string }>();

  const [generalAnswer, setGeneralAnswer] = useState(0);
  const [argumentation, setArgumentation] = useState(0);
  const [differentiation, setDifferentiation] = useState(0);
  const [conclusion, setConclusion] = useState(0);
  const [craftsmanship, setCraftsmanship] = useState(0);
  const [externalReviewerEmail, setExternalReviewerEmail] =
    useState(emailFromQuery);

  const [responsText, setResponstext] = useState("");

  const handleClick = async () => {
    const reviewData = {
      review: {
        generalAnswer,
        argumentation,
        differentiation,
        conclusion,
        craftsmanship,
        externalReviewerEmail,
      },
      id,
    };
    const { data } = await api.post<string>("/external/uploadReview", {
      data: reviewData,
    });
    setResponstext(data);
    setTimeout(() => {
      setResponstext("");
    }, 5000);
  };

  useEffect(() => {
    const fetchEssay = async () => {
      try {
        const { data } = await api.get("/external/getEssay", {
          params: {
            id,
            externalReviewerEmail,
          },
        });

        console.log(data);

        if (data) {
          setUrl(essayBaseUrl + data.essayURL);
          setGeneralAnswer(data.review.generalAnswer);
          setArgumentation(data.review.argumentation);
          setDifferentiation(data.review.differentiation);
          setConclusion(data.review.conclusion);
          setCraftsmanship(data.review.craftsmanship);
          if (data.review.externalReviewerEmail) {
            setExternalReviewerEmail(data.review.externalReviewerEmail);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchEssay();
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div
      style={{
        backgroundColor: Colors.orange,
        height: "100%",
        display: "flex",
        justifyContent: "space-evenly",
      }}>
      <div
        style={{
          justifyContent: "center",
          flexDirection: "column",
        }}>
        <div style={{ paddingTop: "2rem" }}>
          <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
            <div
              style={{ border: "1px solid black", backgroundColor: "white" }}>
              <Page pageNumber={pageNumber} />
            </div>
          </Document>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}>
          <IconButton
            disabled={pageNumber === 1}
            onClick={() => setPageNumber(pageNumber - 1)}>
            <Icon
              path={mdiArrowLeftCircleOutline}
              color={pageNumber === 1 ? Colors.orange : Colors.white}
              size={2}
            />
          </IconButton>

          <Typography
            variant="h5"
            color="primary"
            style={{ textAlign: "center" }}>
            Page {pageNumber} of {numPages}
          </Typography>

          <IconButton
            disabled={pageNumber === numPages}
            onClick={() => setPageNumber(pageNumber + 1)}>
            <Icon
              path={mdiArrowRightCircleOutline}
              color={pageNumber === numPages ? Colors.orange : Colors.white}
              size={2}
            />
          </IconButton>
        </div>
      </div>
      <div
        style={{
          padding: "2rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          maxWidth: `${window.innerWidth / 3}px`,
        }}>
        <Box borderColor="transparent" style={{ padding: "0.8rem" }}>
          <Typography variant="h5">Overordnet besvarelse</Typography>
          <Typography>
            Besvarer den studerende den opstillede udfordring fyldestgørende og
            konkret?
          </Typography>
          <Rating
            name="simple-controlled-1"
            value={generalAnswer}
            onChange={(_, val) => {
              setGeneralAnswer(val);
            }}
          />
        </Box>

        <Box borderColor="transparent" style={{ padding: "0.8rem" }}>
          <Typography variant="h5">Argumentering</Typography>
          <Typography>
            Understøttes den studerendes essay af relevante kilder eller er den
            studerendes hypoteser velargumenteret og fyldestgørende i sig selv?
          </Typography>
          <Rating
            name="simple-controlled-2"
            value={argumentation}
            onChange={(_, val) => {
              setArgumentation(val);
            }}
          />
        </Box>

        <Box borderColor="transparent" style={{ padding: "0.8rem" }}>
          <Typography variant="h5">Differentiering</Typography>
          <Typography>
            Giver den studerende et nyt og relevant perspektiv på
            problemstillingen?
          </Typography>
          <Rating
            name="simple-controlled-3"
            value={differentiation}
            onChange={(event, val) => {
              setDifferentiation(val);
            }}
          />
        </Box>

        <Box borderColor="transparent" style={{ padding: "0.8rem" }}>
          <Typography variant="h5">Konklusion</Typography>
          <Typography>
            Er den studerende i stand til at samle essayets nøglepointer og
            fremstille relevante løsningsforslag til den overordnede
            problemstilling?
          </Typography>
          <Rating
            name="simple-controlled-4"
            value={conclusion}
            onChange={(_, val) => {
              setConclusion(val);
            }}
          />
        </Box>

        <Box borderColor="transparent" style={{ padding: "0.8rem" }}>
          <Typography variant="h5">Håndværk</Typography>
          <Typography>
            Er essayet godt skrevet, struktureret og grammatisk veludført?
          </Typography>
          <Rating
            name="simple-controlled-5"
            value={craftsmanship}
            onChange={(event, val) => {
              setCraftsmanship(val);
            }}
          />
        </Box>

        <Box
          borderColor="transparent"
          style={{ padding: "0.8rem", paddingBottom: "1.5rem" }}>
          <p>Som ekstern reviewer, skal vi bruge din email:</p>
          <TextField
            placeholder="Skriv din email her"
            value={externalReviewerEmail}
            onChange={(val) => {
              setExternalReviewerEmail(val.target.value);
            }}
          />
        </Box>

        <Button
          onClick={() => handleClick()}
          disabled={externalReviewerEmail === ""}
          color="primary"
          variant="outlined">
          Submit
        </Button>
        <Typography variant="h5" color="primary">
          {responsText}
        </Typography>
      </div>
    </div>
  );
};

export default EssayReviewExternal;
