import {
  Button,
  Container,
  MenuItem,
  TextField,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { FormEvent, useEffect, useState } from "react";
import { headlineStyle, useStyles } from "./styles";
import api from "../../api";
import { calendarTheme } from "../../styles/theme";
import { CSSProperties } from "react";
import { DatePicker } from "@material-ui/pickers";
import { UserType } from "../../interfaces";
import { Colors } from "../../constants";
import Baggrund from "../../images/Background2024.png";
import wait from "waait";
import IOSSwitch from "../../Components/iOSSwitch";
import Select from "react-select";
import {
  DropDownOption,
  GroupedDropDownOptions,
  StudyTitle,
  otherOption,
} from "../../Context/interfaces";
import {
  getInstitutionOptions,
  getSelectedInsitution,
} from "../../data/institutions/institutionDataHelper";
import {
  getEducationOptions,
  getSelectedEducation,
} from "../../data/educations/educationDataHelper";
import React from "react";
import { selectStyle } from "../Auth/Register/styles";
import { useQuery } from "react-query";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/build/index.css";
import {
  getNationalityOptions,
  getSelectedNationalityOption,
} from "../../data/nationalities/nationalities";

const initialState: UserType = {
  name: "",
  phone: "",
  email: "",
  postalCode: "",
  nationality: "",
  study: "",
  studyEnd: null,
  vegetarian: false,
  glutenIntolerant: false,
  vegan: false,
  institution: "",
  semester: "",
  studyTitle: "Bachelor",
  exchangeStudent: false,
  permitCompanySharing: false,
};

const institutionOptions: DropDownOption[] = getInstitutionOptions();
const groupedEducationOptions: GroupedDropDownOptions[] = getEducationOptions();
const countryOptions: GroupedDropDownOptions[] = getNationalityOptions();

const ProfilePage = (): JSX.Element => {
  const [closedDiateryChange, setClosedDiateryChange] = useState(false); 
  const [userData, setUserData] = useState<UserType | null>(initialState);
  const [showError, setShowError] = useState(false);
  const [sumbissionsMessage, setSubmissionsMessage] = useState("");
  const [selectedInstitutionOption, setSelectedInstitutionOption] =
    React.useState<DropDownOption | null>(undefined);
  const [selectedEducationOption, setSelecetedEducationOption] =
    React.useState<DropDownOption | null>(undefined);
  const [selectedNationality, setSelectedNationality] =
    useState<DropDownOption | null>(undefined);

  const { button, input } = useStyles();

  const buttonOneDisabled = !(
    userData.name &&
    userData.phone &&
    userData.email &&
    userData.nationality &&
    userData.postalCode &&
    userData.study &&
    userData.studyEnd &&
    userData.institution &&
    userData.semester &&
    userData.studyTitle
  );

  const { data, isError, isLoading, isSuccess, refetch } = useQuery(
    "getCurrentProfile",
    () => {
      const timestamp = new Date().getTime(); // To avoid caching
      return api.get<UserType>("/getCurrentProfile?" + timestamp.toString());
    }
  );

  useEffect(() => {
    if (isSuccess && data) {
      const fetchedUserData = data.data;
      setUserData(fetchedUserData);
      
      // Close diatery 3 weeks before submission
      const currentDate = new Date();
      const threeWeeksBeforeNovemberFirst = new Date(currentDate.getFullYear(), 10, 1); // November 1st
      threeWeeksBeforeNovemberFirst.setDate(threeWeeksBeforeNovemberFirst.getDate() - 28); // Subtract 21 days

      const isThreeWeeksBeforeNovemberFirst = currentDate >= threeWeeksBeforeNovemberFirst;

      setClosedDiateryChange(isThreeWeeksBeforeNovemberFirst)

      const selectedInstitution: DropDownOption = getSelectedInsitution(
        fetchedUserData.institution,
        institutionOptions
      );
      setSelectedInstitutionOption(selectedInstitution);

      const selectedEducation: DropDownOption = getSelectedEducation(
        fetchedUserData.study,
        groupedEducationOptions[1].options
      );
      setSelecetedEducationOption(selectedEducation);

      const selectedNationality: DropDownOption = getSelectedNationalityOption(
        fetchedUserData.nationality
      );
      setSelectedNationality(selectedNationality);
    }
  }, [data]);

  if (isLoading) {
    return (
      <ThemeProvider theme={calendarTheme}>
        <div style={backgroundStyle}>Loading...</div>
      </ThemeProvider>
    );
  } else if (isError) {
    return (
      <ThemeProvider theme={calendarTheme}>
        <div style={backgroundStyle}>An error occurred. Try again later.</div>
      </ThemeProvider>
    );
  }

  const handleInputChange = (event, field, setStateCallback) => {
    if (event.target) {
      event.persist();
      setStateCallback((prev) => ({
        ...prev,
        [field]: event.target.value,
      }));
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await api.post("/updateCurrentProfile", userData);

      const currentUser = {
        email: response.data.email,
        expiresIn: response.data.expiresIn,
        token: response.data.token,
      };

      localStorage.setItem("currentUser", JSON.stringify(currentUser));

      setSubmissionsMessage(response.data.message);
    } catch (error) {
      setSubmissionsMessage(
        "You entered invalid data... None has been updated"
      );
    }

    setShowError(true);
    await wait(3000);
    setShowError(false);
    refetch();
  };

  return (
    <ThemeProvider theme={calendarTheme}>
      <div style={backgroundStyle}>
        <Container maxWidth="sm">
          <div style={formStyle}>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Typography variant="h4" style={headlineStyle}>
                UPDATE PROFILE
              </Typography>
              <TextField
                required
                label="FULL NAME"
                style={{ display: "flex", marginBottom: 20 }}
                value={userData.name}
                onChange={(event) =>
                  handleInputChange(event, "name", setUserData)
                }
              />

              <TextField
                required
                label="EMAIL"
                style={{ display: "flex", marginBottom: 20 }}
                value={userData.email}
                onChange={(event) =>
                  handleInputChange(event, "email", setUserData)
                }
              />
              <TextField
                required
                label="POSTAL CODE"
                style={{ display: "flex", marginBottom: 20 }}
                value={userData.postalCode}
                onChange={(event) =>
                  handleInputChange(event, "postalCode", setUserData)
                }
              />

              <p style={{ color: "white" }}>Nationality</p>
              <Select
                placeholder="Nationality"
                value={selectedNationality}
                styles={selectStyle}
                onChange={(e) => {
                  setSelectedNationality(e);
                  const nationality = e.value;
                  setUserData((prev) => ({
                    ...prev,
                    nationality: nationality,
                  }));
                }}
                options={countryOptions}
                isSearchable={true}
              />

              <p style={{ color: "white" }}>Phone number</p>

              <PhoneInput
                defaultCountry="dk"
                value={
                  userData.phone.startsWith("+")
                    ? userData.phone
                    : "+45" + userData.phone
                }
                onChange={(event) =>
                  setUserData((prev) => ({
                    ...prev,
                    phone: event,
                  }))
                }
                style={{ paddingTop: "0rem", paddingBottom: "1rem" }}
                inputProps={{
                  autoComplete: "tel",
                }}
              />

              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  style={{ marginRight: "1rem", color: "white" }}>
                  VEGETARIAN {closedDiateryChange ? " - NO FURTHER CHANGE THIS YEAR" : ""}
                </Typography>
                <div style={{ paddingLeft: "4.35rem" }}>
                  <IOSSwitch
                    disabled={closedDiateryChange}
                    checked={userData.vegetarian === true}
                    onChange={(event) => {
                      const value = event.target.checked ? true : false;
                      setUserData((prev) => ({
                        ...prev,
                        vegetarian: value,
                        vegan: value ? false : prev.vegan,
                      }));
                    }}
                  />
                </div>
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  style={{ marginRight: "8.2rem", color: "white" }}>
                  VEGAN {closedDiateryChange ? " - NO FURTHER CHANGE THIS YEAR" : ""}
                </Typography>
                <IOSSwitch
                  disabled={closedDiateryChange}
                  checked={userData.vegan === true}
                  onChange={(event) => {
                    const value = event.target.checked ? true : false;
                    setUserData((prev) => ({
                      ...prev,
                      vegan: value,
                      vegetarian: value ? false : prev.vegetarian,
                    }));
                  }}
                />
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  style={{ marginRight: "1rem", color: "white" }}>
                  GLUTEN INTOLERANT {closedDiateryChange ? " - NO FURTHER CHANGE THIS YEAR" : ""}
                </Typography>
                <IOSSwitch
                  disabled={closedDiateryChange}
                  checked={userData.glutenIntolerant === true}
                  onChange={(event) => {
                    const value = event.target.checked ? true : false;
                    setUserData((prev) => ({
                      ...prev,
                      glutenIntolerant: value,
                    }));
                  }}
                />
              </div>
              <Typography variant="h5" style={headlineStyle}>
                STUDY
              </Typography>

              <p style={{ color: "white" }}>Institution</p>

              <Select
                placeholder="Institution"
                value={selectedInstitutionOption}
                styles={selectStyle}
                onChange={(e) => {
                  setSelectedInstitutionOption(e);
                  let institution = e.value;
                  if (e === otherOption) {
                    institution = "";
                  }
                  setUserData((prev) => ({
                    ...prev,
                    institution: institution,
                  }));
                }}
                options={institutionOptions}
                isSearchable={true}
              />
              {selectedInstitutionOption &&
                selectedInstitutionOption.value === otherOption.value && (
                  <div
                    style={{
                      marginTop: "1rem",
                    }}>
                    <TextField
                      placeholder="What is your educational institution?"
                      value={userData.institution ? userData.institution : ""}
                      onChange={(event) =>
                        handleInputChange(event, "institution", setUserData)
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                )}

              <p style={{ color: "white" }}>Education</p>

              <Select
                placeholder="Education"
                value={selectedEducationOption}
                styles={selectStyle}
                onChange={(e) => {
                  setSelecetedEducationOption(e);
                  let study = e.value;
                  if (e.value === otherOption.value) {
                    study = "";
                  }
                  setUserData((prev) => ({
                    ...prev,
                    study: study,
                  }));
                }}
                options={groupedEducationOptions}
                isSearchable={true}
              />

              {selectedEducationOption &&
                selectedEducationOption.value === otherOption.value && (
                  <div style={{ marginTop: "1rem" }}>
                    <TextField
                      placeholder="What is your education?"
                      value={userData.study ? userData.study : ""}
                      onChange={(event) =>
                        handleInputChange(event, "study", setUserData)
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                )}

              <ThemeProvider theme={calendarTheme}>
                <DatePicker
                  style={{
                    width: "100%",
                    color: "black",
                    marginBottom: 20,
                    marginTop: 20,
                  }}
                  label="YOUR STUDIES END IN..."
                  disablePast
                  views={["year", "month"]}
                  color="secondary"
                  value={userData.studyEnd}
                  onChange={(date) => {
                    setUserData((prev) => ({
                      ...prev,
                      studyEnd: date,
                    }));
                  }}
                />
              </ThemeProvider>

              <TextField
                label={"YOUR CURRENT SEMESTER"}
                value={userData.semester}
                select
                style={{ display: "flex", marginBottom: 20 }}
                className={input}
                onChange={({ target }) =>
                  setUserData((prev) => ({
                    ...prev,
                    semester: target.value,
                  }))
                }>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((key) => (
                  <MenuItem key={key} value={String(key)}>
                    {key + ". semester"}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                label={"TYPE OF DEGREE"}
                value={userData.studyTitle}
                select
                style={{ display: "flex", marginBottom: 20 }}
                className={input}
                onChange={({ target }) =>
                  setUserData((prev) => ({
                    ...prev,
                    studyTitle: target.value as StudyTitle,
                  }))
                }>
                {["Bachelor", "Master", "PhD"].map((key) => (
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                ))}
              </TextField>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingBottom: "2rem",
                }}>
                <Typography
                  variant="subtitle1"
                  style={{ marginRight: "1rem", color: "white" }}>
                  EXCHANGE STUDENT
                </Typography>
                <div style={{ paddingLeft: "0rem" }}>
                  <IOSSwitch
                    checked={userData.exchangeStudent === true}
                    onChange={(event) => {
                      const value = event.target.checked ? true : false;
                      setUserData((prev) => ({
                        ...prev,
                        exchangeStudent: value,
                      }));
                    }}
                  />
                </div>
              </div>

              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingBottom: "2rem",
                }}>
                <Typography
                  variant="subtitle1"
                  style={{ marginRight: "1rem", color: "white" }}>
                  ALLOW COMPANIES TO CONTACT YOU
                </Typography>
                <div style={{ paddingLeft: "0rem" }}>
                  <IOSSwitch
                    checked={userData.permitCompanySharing === true}
                    onChange={(event) => {
                      const value = event.target.checked ? true : false;
                      setUserData((prev) => ({
                        ...prev,
                        permitCompanySharing: value,
                      }));
                    }}
                  />
                </div>
              </div> */}

              {showError ? (
                <div
                  style={{
                    border: `2px solid ${Colors.white}`,
                    borderRadius: 10,
                    padding: "0.5rem",
                  }}>
                  <Typography
                    style={{ textAlign: "center" }}
                    variant={"h5"}
                    color="secondary">
                    {sumbissionsMessage}
                  </Typography>
                </div>
              ) : (
                <div style={{ paddingBottom: "2rem" }}>
                  <Button
                    disableElevation
                    disabled={buttonOneDisabled}
                    style={{
                      padding: "0.5rem",
                      fontSize: 22,
                      fontFamily: "futura-pt",
                    }}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    className={button}>
                    Update Information
                  </Button>
                </div>
              )}
            </form>
          </div>
        </Container>
      </div>
    </ThemeProvider>
  );
};

export default ProfilePage;

const backgroundStyle = {
  backgroundImage: `url(${Baggrund})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const formStyle: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  flexShrink: 0,
  marginBottom: "1rem",
};
