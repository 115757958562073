import { AxiosError, AxiosResponse } from "axios";
import api from "../api";
import { IUser } from "../interfaces";
import { AuthAction, AuthState, LoginPayload } from "./interfaces";

export const loginUser = async (
  dispatch: React.Dispatch<AuthAction>,
  loginPayload: LoginPayload
) => {
  try {
    const { email, password, recaptchaToken } = loginPayload;
    dispatch({ type: "REQUEST_LOGIN" });
    const { data } = await api.post<LoginPayload, AxiosResponse<IUser>>(
      "/login",
      {
        data: {
          email: email,
          password: password,
          recaptchaToken: recaptchaToken,
        },
      }
    );

    if (data.email) {
      const authData: AuthState = { errorMessage: "", loading: false, ...data };
      dispatch({ type: "LOGIN_SUCCESS", payload: authData });
      localStorage.setItem("currentUser", JSON.stringify(data));
      return data;
    }
  } catch (e) {
    console.log(e.response);
    const error = <AxiosError<{ msg: string; status: boolean }>>e;
    dispatch({ type: "LOGIN_ERROR", error: "" });
    setTimeout(() => {
      dispatch({ type: "LOGIN_ERROR", error: error.response?.data.msg });
    }, 200);
  }
};

export const logout = async (dispatch: (arg0: { type: string }) => void) => {
  dispatch({ type: "LOGOUT" });
  localStorage.removeItem("currentUser");
};
