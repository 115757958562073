import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors } from "../../constants";
import "../../index.css";
import { isMobile } from "react-device-detect";

export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    title: {
      color: "#fff",
    },
    colorDefault: {
      backgroundColor: Colors.orange,
    },
    button: {
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",
      borderRadius: 24,
      color: Colors.orange,
    },
    loginButton: {
      paddingLeft: "4rem",
      paddingRight: "4rem",
      borderRadius: 24,
      color: Colors.orange,
    },
    input: {
      width: "100%"
    }
  });
});

export const headlineStyle: React.CSSProperties = {
  color: "#FFF",
  marginBottom: 20,
  marginTop: 40,
};

export const stepStyle: React.CSSProperties = {
  color: "#FFF",
  marginTop: isMobile ? -40 : 0,
};

export const modalStyleText: React.CSSProperties = {
  color: "#FFA500",
  marginBottom: 20,
  marginTop: 20,
};