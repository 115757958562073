import { Url } from "url";

export interface TrackType {
  title: string;
  subText: string;
  description: string;
  priority: number;
}
export interface ChallengeType {
  subTitle?: string;
  image: Url | string | any;
  title: string;
  description: string;
}
export interface ADayAtType {
  subText: string;
  image: any;
  title: string;
  description: string;
  priority: number;
}

export const defaultTracks: TrackType[] = [
  {
    title: "OPPORTUNITIES OF TOMORROW",
    subText:
      "How can leaders use the opportunities of today to prepare for the challenges of tomorrow?",
    description: `Life is defined by adaptation. At all times, a successful organisation must be prepared to review its practices and change course. By utilising the opportunities of today, companies aim to prepare for the most fundamental challenges of tomorrow. Naturally, everyone wishes to play a central role in the next big leap, and if leadersdare to question the status quo, ideas can transform into future solutions.\n\n\n\n`,
    priority: 0,
  },
  {
    title: "BUSINESSES OF TOMORROW",
    subText:
      "How does a leader create a business structure capable of lasting through unforeseen changes and challenges?",
    description: `As the workforce is becoming more decentralised, online marketplaces for freelance services like Fiverr and Craigslist have seen remarkable growth. Both externally and internally, digitalisation forces change, and it rewards those that seek out new opportunities. Competitive edges might be derived from new business models or by mastering new capabilities, but they ultimately rely on proactive leadership. No stone is to be left unturned as the leaders of today build resilient businesses for tomorrow.\n`,
    priority: 0,
  },
  {
    title: "RESPONSIBILITIES OF TOMORROW",
    subText:
      "How do leaders handle their intertwining responsibilities and choose which society they want to build?",
    description: `Social inequality and environmental change have made individuals more aware of how corporations impact their surroundings. This has required leaders to consider how the perceived diverging objectives of shareholders and society are placing them in a position of increased accountability. In choosing between several pressing concerns, it is vital to balance success within the organisation with responsibility towards society.\n\n\n`,
    priority: 0,
  },
  {
    title: "ECONOMIES OF TOMORROW",
    subText:
      "How can companies contribute to a strong future economy while accountingfor the challenges to multinational cooperation?",
    description: `As prosperity increases across the world, patterns of demand will shift. Additionally, protectionist policies and supply shocks risk jeopardising the existing value chains that multinational corporations rely upon.  Increased trade has long been a facilitator of growth and prosperity for all economies. However, while corporations are expanding their connections globally, some governments are decreasing theirs. Thus, leaders are required to find viable ways of securing future advancement while facing rising barriers to trade.\n`,
    priority: 0,
  },
];

export const defaultChallenges: ChallengeType[] = [
  {
    image: "",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur rutrum arcu vel mi tempus, quis tempus nisl porttitor. Ut commodo tempus semper. Donec eu massa eget erat bibendum volutpat ac sed lectus. Morbi vestibulum at ligula non ultricies. Suspendisse sed feugiat mauris. Etiam volutpat ex vitae lobortis iaculis. Maecenas et feugiat mi. Cras molestie convallis lorem viverra volutpat. Suspendisse quis libero non leo dictum fringilla. Mauris lobortis convallis mauris, sit amet pharetra justo fermentum sit amet. ",
    title: "Darkness !!!!!!my old friend",
  },
  {
    image: "",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur rutrum arcu vel mi tempus, quis tempus nisl porttitor. Ut commodo tempus semper. Donec eu massa eget erat bibendum volutpat ac sed lectus. Morbi vestibulum at ligula non ultricies. Suspendisse sed feugiat mauris. Etiam volutpat ex vitae lobortis iaculis. Maecenas et feugiat mi. Cras molestie convallis lorem viverra volutpat. Suspendisse quis libero non leo dictum fringilla. Mauris lobortis convallis mauris, sit amet pharetra justo fermentum sit amet. ",
    title: "adSDMSIOSD MIOAPDS",
  },
  {
    image: "",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur rutrum arcu vel mi tempus, quis tempus nisl porttitor. Ut commodo tempus semper. Donec eu massa eget erat bibendum volutpat ac sed lectus. Morbi vestibulum at ligula non ultricies. Suspendisse sed feugiat mauris. Etiam volutpat ex vitae lobortis iaculis. Maecenas et feugiat mi. Cras molestie convallis lorem viverra volutpat. Suspendisse quis libero non leo dictum fringilla. Mauris lobortis convallis mauris, sit amet pharetra justo fermentum sit amet. ",
    title: "dsaijbsdaiouy adbs8ob",
  },
];
export const defaultADayAt: ADayAtType[] = [
  {
    image: "",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur rutrum arcu vel mi tempus, quis tempus nisl porttitor. Ut commodo tempus semper. Donec eu massa eget erat bibendum volutpat ac sed lectus. Morbi vestibulum at ligula non ultricies. Suspendisse sed feugiat mauris. Etiam volutpat ex vitae lobortis iaculis. Maecenas et feugiat mi. Cras molestie convallis lorem viverra volutpat. Suspendisse quis libero non leo dictum fringilla. Mauris lobortis convallis mauris, sit amet pharetra justo fermentum sit amet. ",
    title: "Darkness my old friend",
    subText:
      "How do leaders handle their intertwining responsibilities and choose which society they want to build?",
    priority: 0,
  },
  {
    image: "",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur rutrum arcu vel mi tempus, quis tempus nisl porttitor. Ut commodo tempus semper. Donec eu massa eget erat bibendum volutpat ac sed lectus. Morbi vestibulum at ligula non ultricies. Suspendisse sed feugiat mauris. Etiam volutpat ex vitae lobortis iaculis. Maecenas et feugiat mi. Cras molestie convallis lorem viverra volutpat. Suspendisse quis libero non leo dictum fringilla. Mauris lobortis convallis mauris, sit amet pharetra justo fermentum sit amet. ",
    title: "adSDMSIOS DMIOAPDS",
    subText:
      "How do leaders handle their intertwining responsibilities and choose which society they want to build?",
    priority: 0,
  },
  {
    image: "",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur rutrum arcu vel mi tempus, quis tempus nisl porttitor. Ut commodo tempus semper. Donec eu massa eget erat bibendum volutpat ac sed lectus. Morbi vestibulum at ligula non ultricies. Suspendisse sed feugiat mauris. Etiam volutpat ex vitae lobortis iaculis. Maecenas et feugiat mi. Cras molestie convallis lorem viverra volutpat. Suspendisse quis libero non leo dictum fringilla. Mauris lobortis convallis mauris, sit amet pharetra justo fermentum sit amet. ",
    title: "dsaijbsda iouyadbs8ob",
    subText:
      "How do leaders handle their intertwining responsibilities and choose which society they want to build?",
    priority: 0,
  },
];
