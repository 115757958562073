export const educations = [
  "Accounting, Strategy and Control",
  "Advanced Economics and Finance",
  "Advanced Power Electronics",
  "Afrikastudier",
  "Agrobiologi",
  "Agro-Environmental Management",
  "Agronomi",
  "Amerikanske studier",
  "Antropologi",
  "Anvendt filosofi",
  "Anvendt kulturanalyse",
  "Anvendt matematik",
  "Applied Economics and Finance",
  "Applied Industrial Electronics",
  "Arabisk- og islamstudier",
  "Arkitektur og design",
  "Arkæologi",
  "Asienstudier",
  "Assyriologi",
  "Astronomi",
  "Audiologi",
  "Audiologopædi",
  "Biblioteksvidenskab, it og kommunikation",
  "Bioengineering",
  "Bioentreprenørskab",
  "Bioinformatik",
  "Biokemi",
  "Biokemi og molekylær biologi",
  "Biologi",
  "Biomedicin",
  "Biomedicinsk teknologi",
  "Bioteknologi",
  "Brand and Communications Management",
  "Brasilianske studier",
  "Building Energy Design",
  "Business Administration and Bioentrepreneurship",
  "Business Administration and Data Science",
  "Business Administration and Digital Business",
  "Business Administration and Digital Management ",
  "Business Administration and Information Systems",
  "Business Administration and Innovation in Health Care",
  "Business Administration and Philosophy",
  "Business Administration and Service Management",
  "Business Administration and Sociology",
  "Business and Development Studies",
  "Business, Asian Language and Culture - International Business in Asia",
  "Business, Language and Culture",
  "By-, energi- og miljø­planlægning",
  "Byggeledelse og bygnings­informatik",
  "Byggeledelse",
  "Byggeri",
  "Byggeri og anlæg",
  "Bygningsingeniør",
  "Bygningsdesign",
  "Bæredygtig energiteknik",
  "Bæredygtig skov- og naturforvaltning",
  "Bæredygtige byggeprocesser",
  "Bæredygtigt design",
  "Chemical Engineering and Biotechnology",
  "Chemical Engineering",
  "Children's Literature, Media and Culture",
  "Civilingeniør i Bygningsteknik",
  "Civilingeniør i Energisystemer",
  "Civilingeniør i Engineering, Innovation and Business",
  "Civilingeniør i Mechatronics",
  "Civilingeniør i Product Development and Innovation",
  "Civilingeniør i Software Engineering",
  "Civilingeniør i Spiludvikling og læringsteknologi",
  "Civilingeniør i Sundheds- og Velfærdsteknologi",
  "Cognitive Science ",
  "Computer Engineering",
  "Computer Science",
  "Computer­teknologi",
  "Culture, Communi­cation and Globalization",
  "Cyber- og computer­teknologi",
  "Cyber Security",
  "Dansk",
  "Datalogi",
  "Datavidenskab",
  "Datavidenskab og machine learning",
  "Design og anvendelse af kunstig intelligens",
  "Designkultur",
  "Designkultur og økonomi",
  "Didaktik",
  "Digital design",
  "Digitalisering og applikations­udvikling",
  "Diplomingeniør i Bygningsteknik",
  "Diplomingeniør i Global Management and Manufacturing",
  "Diplomingeniør i Integreret Design",
  "Diplomingeniør i Mechatronics",
  "Diplomingeniør i Produktion",
  "Diversity and Change Management",
  "Dramaturgi",
  "Economics and Business Administration",
  "Electronic Systems",
  "Elektrisk energiteknologi",
  "Electronics",
  "Elektronik",
  "Elektronik og systemdesign",
  "Elektroteknologi",
  "Energiingeniør",
  "Energy Engineering",
  "Engelsk",
  "Entrepreneurial Business Engineering",
  "Erhvervssprog og international erhvervskommunikation",
  "Erhvervsøkonomi og Matematik",
  "Erhvervsøkonomi og Psykologi",
  "Erhvervsøkonomi og Virksomhedskommunikation",
  "Erhvervsøkonomi, cand.merc.",
  "Erhvervsøkonomi, cand.soc.",
  "Erhvervsøkonomi, HA",
  "Erhvervsøkonomi, HA - Energi management",
  "Erhvervsøkonomi, HA - Entreprenørskab og Innovation",
  "Erhvervsøkonomi, HA - Sports- og eventmanagement",
  "Erhvervsøkonomi, HA - Digitalisering og Forretningsudvikling",
  "Erhvervsøkonomi, HA - Global Business Relationships",
  "Erhvervsøkonomi, HA - Human Resource Management",
  "Erhvervsøkonomi, HA - International Business",
  "Erhvervsøkonomi, HA - Marketing og Brand Management",
  "Erhvervsøkonomi, HA - Europæisk business",
  "Erhvervsøkonomi, HA - Markeds- og kulturanalyse",
  "Erhvervsøkonomi, HA - Projektledelse",
  "Erhvervsøkonomi-erhvervssprog (Negot) - engelsk eller tysk",
  "Erhvervsøkonomi-jura, HA(jur.)",
  "Erhvervsøkonomi-filosofi, HA(fil.)",
  "Erhvervsøkonomi-informationsteknologi, HA(it.)",
  "Europas religiøse rødder",
  "Europastudier",
  "Europæisk etnologi",
  "Europæiske Studier",
  "Farmaci",
  "Film- og medievidenskab",
  "Filosofi",
  "Finance and Investments",
  "Finance and Strategic Management",
  "Finansiering og Regnskab",
  "Folkesundhedsvidenskab",
  "Forhistorisk arkæologi",
  "Forretningsudvikling, BDE",
  "Forsikringsmatematik",
  "Fransk sprog og kultur",
  "Fysik",
  "Fysik og teknologi",
  "Fødevareinnovation og sundhed",
  "Fødevareteknologi",
  "Fødevarevidenskab",
  "General Management and Analytics",
  "Generel pædagogik",
  "Geofysik",
  "Geografi",
  "Geologi",
  "Global Environment and Development",
  "Global ledelse og design af produktionsnetværk, GMM",
  "Global skovforvaltning",
  "Global sundhed",
  "Global udvikling",
  "Globale Forretnings­systemer",
  "Globale områdestudier",
  "Grønlandske og arktiske studier",
  "Historie",
  "Human ernæring",
  "Human Resource Management",
  "Human Security",
  "Humanbiologi",
  "Humanfysiologi",
  "Humanistisk-samfundsvidenskabelig idrætsvidenskab",
  "Husdyrvidenskab",
  "Idéhistorie",
  "Idræt",
  "Immunologi og inflammation",
  "Indianske sprog og kulturer",
  "Indien- og sydasienstudier",
  "Indoor Environmental and Energy Engineering",
  "Industrial Design",
  "Information Studies (Human Centered Informatics)",
  "Informationsteknologi",
  "Informationsvidenskab",
  "Ingeniørvidenskab",
  "Innovation og digitalisering",
  "Innovative Communication Technologies and Entrepreneurship",
  "Integrerede fødevarestudier",
  "Intelligent Reliable Systems",
  "Interaktions­design",
  "Interaktive digitale medier",
  "Interkulturel pædagogik og dansk som andetsprog",
  "Interkulturelle markedsstudier",
  "Interkulturelle studier",
  "International Business",
  "International Business and Politics",
  "International Business Development",
  "International fødevarekvalitet og sundhed",
  "International Marketing and Management",
  "International Relations",
  "International Shipping and Trade",
  "International virksomhedskommunikation",
  "Internationale studier ",
  "Interreligiøse islamstudier",
  "It og kognition",
  "It, læring og organisatorisk omstilling",
  "Italiensk sprog og kultur",
  "It-Arkitektur",
  "It-didaktisk design",
  "It-ledelse",
  "IT-Produktudvikling",
  "Japanstudier",
  "Jordbrugsøkonomi",
  "Journalism, Media and Globalisation (Erasmus Mundus)",
  "Journalistik",
  "Jura",
  "Kemi ",
  "Kemi og bioteknologi",
  "Kemiteknologi",
  "Kinastudier",
  "Klassisk arkæologi",
  "Klassisk filologi ",
  "Klassisk græsk",
  "Klimaforandringer",
  "Klinisk biomekanik",
  "Klinisk ernæring",
  "Klinisk tandteknik",
  "Klinisk videnskab og teknologi",
  "Kognition og kommunikation",
  "Kommunikation",
  "Kommunikation og digitale medier",
  "Kommunikation og it",
  "Kriminologi",
  "Kunst og teknologi",
  "Kunsthistorie",
  "Kunstig intelligens",
  "Kvanteinformationsvidenskab",
  "Kvantitativ biologi og sygdomsmodellering",
  "Landinspektør­videnskab",
  "Landskabsarkitektur",
  "Latin",
  "Ledelse og informatik i byggeriet",
  "Lighting Design",
  "Lingvistik",
  "Litteraturhistorie",
  "Litteraturvidenskab",
  "Lægemiddelvidenskab",
  "Læring og forandrings­processer",
  "Management Engineering",
  "Management of Innovation and Business Development",
  "Market and Management Anthropology",
  "Maskinteknik",
  "Master i arbejdsmiljøledelse",
  "Matematik",
  "Matematik-teknologi",
  "Matematik-Økonomi",
  "Mechanical Engineering",
  "Medialogi",
  "Medicin",
  "Medicin med industriel specialisering",
  "Medicin og teknologi",
  "Medicinalkemi",
  "Medievidenskab ",
  "Mekanik",
  "Mellemøstens sprog og samfund",
  "Migrationsstudier",
  "Miljø- og konfliktanalyse",
  "Miljø- og natur-ressourceøkonomi",
  "Miljøvidenskab",
  "Moderne kultur",
  "Molekylær biomedicin",
  "Molekylær ernæring og fødevareteknologi",
  "Molekylær medicin",
  "Molekylærbiologi",
  "Musik",
  "Musikterapi",
  "Musikvidenskab",
  "Muskuloskeletal fysioterapi",
  "Nanobio­technology",
  "Nanoscience",
  "Naturforvaltning",
  "Naturressourcer og udvikling",
  "Neurovidenskab",
  "Nordisk sprog og litteratur",
  "Nærorientalsk arkæologi",
  "Odontologi",
  "Oldtidskundskab",
  "Oplevelses­design",
  "Oplevelsesøkonomi ",
  "Optometri og synsvidenskab",
  "Organisational Innovation and Entrepreneurship",
  "Organisatorisk læring",
  "People and Business Development",
  "Physics and Technology",
  "Politik og administration",
  "Politik og økonomi",
  "Portugisiske og brasilianske studier",
  "Produkt- og Design­psykologi",
  "Professionsbachelor i engelsk og digital markedskommunikation",
  "Psykologi",
  "Public Management and Social Development",
  "Pædagogik",
  "Quantitative Economics",
  "Religionsvidenskab",
  "Retorik",
  "Revision",
  "Revisorkandidat",
  "Risk and Safety Management",
  "Robotics",
  "Robotteknologi",
  "Ruslandstudier",
  "Sales Management",
  "Samfundsfag",
  "Service Systems Design",
  "Sikkerheds- og risikoledelse",
  "Skovbrugsvidenskab",
  "Social datavidenskab",
  "Socialrådgiver",
  "Socialt arbejde",
  "Sociologi",
  "Software",
  "Softwareteknologi",
  "Soils and Global Change",
  "Sound and Music Computing",
  "Sprog og internationale studier",
  "Sprogpsykologi",
  "Statistik",
  "Statskundskab",
  "STEM-undervisning",
  "Strategic Design and Entrepreneurship",
  "Strategic Value Chain Management (Cand. Merc)",
  "Strategy, Organisation and Leadership",
  "Structural and Civil Engineering",
  "Structural Design and Analysis",
  "Sundhed og informatik",
  "Sundhedsfaglig kandidatuddannelse",
  "Sundhedsteknologi",
  "Supply Chain Management",
  "Surveying and Planning",
  "Surveying, Planning and Land Management",
  "Sustainable Biotechnology",
  "Sustainable Cities",
  "Sustainable Design",
  "Sustainable Energy Engineering",
  "Sustainable Heritage Management ",
  "Sustainable Tourism and Hospitality Management",
  "Sygepleje",
  "Tandpleje",
  "Teater- og performancestudier",
  "Tekno­antropologi",
  "Teknologibaseret forretningsudvikling",
  "Teologi",
  "Tourism",
  "Tværkulturelle studier",
  "Tysk",
  "Uddannelsesantropologi og globalisering",
  "Uddannelsesvidenskab",
  "Urban Design",
  "Urban, Energy and Environmental Planning",
  "Vand og miljø",
  "Veje og trafik",
  "Veterinærmedicin",
  "Videnskabsstudier ",
  "Visuel kultur",
  "Water and Environmental Engineering",
  "Ægyptologi",
  "Æstetik og kultur",
  "Økonomi",
  "Økonomisk Markedsføring",
  "Østeuropastudier"
  ];
