import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Direction,
  IconButton,
  makeStyles,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { Dispatch, SetStateAction, useState } from "react";
import Icon from "@mdi/react";
import { mdiArrowLeftCircleOutline, mdiArrowRightCircleOutline } from "@mdi/js";
import { Colors } from "../../../constants";
import BottomDots from "../BottomDots/BottomDots";
import { calendarTheme } from "../../../styles/theme";
import { ADayAtType, TrackType } from "../Fallbacks";
import TrackCard from "../Tracks/TrackCard";

const useStyles = makeStyles({
  headerCenter: {
    textAlign: "center",
    padding: "1rem",
  },
  
  textStyle: {
    whiteSpace: "pre-line",
  },
  
  titleStyle: {
    textAlign: "center",
    margin: "1rem",
    fontWeight: "bold",
  },
});
interface Props {
  nextPageCallback: (direction: Direction) => void;
  aDayAts: ADayAtType[];
  setPriority: Dispatch<SetStateAction<TrackType[]>>;
  resetValues: () => void;
}

const ADayAtPage = (props: Props) => {
  const getCurrentPrio = () => {
    let biggest = 0;
    const newArray = aDayAts;
    newArray.forEach(
      (value, index) => {
        if(value.priority > biggest){
          biggest = value.priority;
        }
      }
    )
    return biggest + 1;
  };
  const { headerCenter, titleStyle, textStyle } = useStyles();
  const { nextPageCallback, aDayAts, setPriority, resetValues } = props;
  const [priorityToGive, setPriorityToGive] = useState<number>(getCurrentPrio());

  console.log(aDayAts);
  const setPrio = (cardNumber: number) => {
    const newArray = aDayAts;
    if (cardNumber > 3) return;

    if (aDayAts[cardNumber].priority !== 0) return;
    newArray[cardNumber].priority = priorityToGive;
    setPriorityToGive(priorityToGive + 1);
    setPriority([...newArray]);
  };

  const disabled = () => {
    return !(noPrioZero() || allPrioZero());
  }

  const noPrioZero = () => {
    let noneZero = true;
    const newArray = aDayAts;
    newArray.forEach(
      (value, index) => {
        if (value.priority === 0){
          noneZero = false;
        }
      }
    );
    return noneZero;
  }

  const allPrioZero = () => {
    let allZero = true;
    const newArray = aDayAts;
    newArray.forEach(
      (value, index) => {
        if (value.priority !== 0){
          allZero = false;
        }
      }
    );
    return allZero;
  }
  console.log(disabled());
  console.log(noPrioZero());
  console.log(allPrioZero());
  return (
    <div
      style={{
        alignSelf: "center",
        display: "flex",
        justifyContent: "center",
        margin: "2em",
      }}
    >
      <Card>
        <Typography variant={"h4"} className={headerCenter} color={"primary"}>
          {"ARE YOU INTERESTED IN A DAY AT?"}
        </Typography>
        <Typography variant={"h6"} className={headerCenter} color={"primary"}>
          {"(OPTIONAL) "}
        </Typography>
        <Container maxWidth="sm">
          <Typography
            className={headerCenter}
            color={"primary"}
            style={{ fontFamily: "Book Antiqua" }}
          >
            {
              "A day at one of the Premium Partners of Aarhus Symposium is a unique opportunity for selected students to engage with students and representatives of the company. The day gives a profound understanding of the company and its career opportunities."
            }
          </Typography>
        </Container>
        <Box display="flex" flexDirection="column" alignItems="center">
          <ThemeProvider theme={calendarTheme}>
            <Button
              style={{ marginLeft: "1rem" }}
              onClick={() => {
                resetValues();
                setPriorityToGive(1);
              }}
              variant="contained"
              color="primary"
              size="large"
            >
              <Typography variant="button" color="secondary">
                Reset selection
              </Typography>
            </Button>
          </ThemeProvider>
        </Box>

        <CardContent>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-around",
              marginRight: "1rem",
              marginLeft: "1rem",
            }}
          >
            {aDayAts.map((track, i) => {
              return (
                <TrackCard
                  key={i}
                  onPress={() => setPrio(i)}
                  title={track.title}
                  subText={track.subText}
                  text={track.description}
                  image={track.image}
                  number={track.priority}
                >
                  {track.image?.formats?.small?.url ? (
              <CardMedia
                component="img"
                alt="Image Missing"
                image={track.image.formats.small.url}
                title="CardImage"
                className="ADayAtImage"
              />
            ) : (track.image?.formats?.thumbnail?.url ? (
              <CardMedia
                component="img"
                alt="Image Missing"
                image={track.image.formats.thumbnail.url}
                title="CardImage"
                className="ADayAtImage"
              />
            ) : null)}
            <ThemeProvider theme={calendarTheme}>
              <Typography className={titleStyle} variant="h5" color="primary">
                {track.title}
              </Typography>
              <Typography className={textStyle} variant="caption">
                {track.description}
              </Typography>
              <div></div>
              {/* <Typography
                className={underStyle}
                variant="caption"
                color="primary">
                {subText + " dqwujdhqwoidhwquid"}
              </Typography> */}
            </ThemeProvider>
            <br />
            <Box
              height="6em"
              display="flex"
              alignItems="center"
              justifyContent="center">
              <Typography
                className={titleStyle}
                variant={track.priority == 0 ? "h4" : "h2"}
                color="primary">
                {track.priority == 0 ? "Click to select" : track.priority}
              </Typography>
            </Box>
                </TrackCard>
              );
            })}
          </div>
        </CardContent>

        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginRight: "1rem",
            marginLeft: "1rem",
          }}
        >
          <IconButton
            onClick={() => {
              nextPageCallback("ltr");
            }}
          >
            <Icon
              path={mdiArrowLeftCircleOutline}
              color={Colors.orange}
              size={2}
            />
          </IconButton>

          <BottomDots marked={2}></BottomDots>
          <IconButton
            disabled={disabled()}
            onClick={() => {
              nextPageCallback("rtl");
            }}
          >
            <Icon
              path={mdiArrowRightCircleOutline}
              color={disabled() ? Colors.opaqueOrange : Colors.orange}
              size={2}
            />
          </IconButton>
        </div>
      </Card>
    </div>
  );
};

export default ADayAtPage;
