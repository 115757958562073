import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Typography from "@material-ui/core/Typography";
import {
  MuiThemeProvider,
  makeStyles,
  ThemeProvider,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import { calendarTheme } from "../../styles/theme";
import { Colors } from "../../constants";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect, useState } from "react";
import strapiAPI from "../../strapiAPI";
import { isMobile } from "react-device-detect";
import MobileTimelineItem from "./MobileTimelineItem";
import WebTimelineItem from "./WebTimelineItem";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import "./timeline.css";
import {
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  Icon,
  IconButton,
  Toolbar,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import api from "../../api";
import bgImage from "../../images/Background2024.png"
import { headlineStyle } from "../Profile/styles";
import logo from "../../images/logo.png";
import { Parallax } from "react-parallax";
import EssayDialog from "../../Pages/EssayDialog/EssayDialog";
import { useAuthState } from "../../Context";
import Modal from "../../Components/Modal/Modal";
import { useHistory } from "react-router-dom";
import { isBeforeCL } from "../../Components/isBeforeCL";
import { getUserTickets } from "../../Components/ticketLogic"
import { RadioButtonUncheckedSharp } from "@material-ui/icons";


export interface TimeLineData {
  buttonText: string;
  buttonLink: string;
  image: any;
  _id: string;
  title: string;
  description: string;
  date: Date | string;
  time: string;
}

const styles = makeStyles((theme) => ({
  mobile: {
    "&::before": {
      flex: 0,
    },
  },
  icon: {
    overflow: "visible",
    color: Colors.orange,
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  menuLink: { marginBottom: "2rem" },
}));
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    menuLink: { marginBottom: "2rem" },
  })
);

const ASTimeline = () =>{ 
  const { title, appBar } = useStyles();
  const [essayDialogOpen, setEssayDialogOpen] = useState(false);
  const [essayUploadedButtonText, setEssayUploadedButtonText] = useState<string>("");
  const [essayUploaded, setEssayUploaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userTickets, setUserTickets] = useState<string[]>([]);
  const [disableSubmissions, setDisableSubmissions] = useState<boolean>(true);
  
  
  const auth = useAuthState();
  const [timeLineData, setTimeLineData] = useState<TimeLineData[]>([]);
  const history = useHistory();
  const { mobile, icon } = styles();

  useEffect(() => {

    const EnableSubmissions = (enable : boolean) => {
      setDisableSubmissions(!enable);
    }

    (window as any).TestEnableSubmissions = EnableSubmissions;

    const networkCallback = async () => {
      try {
        const { data } = await strapiAPI.get<TimeLineData[]>(
          "/timeline-entries"
        );
        if (data.length) {
          const sortedData = data.sort((a, b) => {
            const ad: any = new Date(a.date),
              bd: any = new Date(b.date);
            return ad - bd;
          });
          setTimeLineData(sortedData);
        }
      } catch (error) {
        console.log(error);
      }
    };
    networkCallback();

    const fetchEssay = async () => {
      const { data } = await api.get<string>("currentUserEssay", {});

      if (data.includes(".pdf")) {
        setEssayUploaded(true);
        setEssayUploadedButtonText("EDIT YOUR SUBMISSION AND CHOICES");
        return;
      }
      setEssayUploadedButtonText("UPLOAD YOUR SUBMISSION NOW!");
      const beforeSubmissionDeadline = isBeforeCL();
      setDisableSubmissions(beforeSubmissionDeadline);

      if(disableSubmissions){
      setEssayUploadedButtonText("SUBMISSIONS ARE CLOSED.");
      return;
      }else{
        showToast();
      }

    };
    fetchEssay();

    const isProfileUpdated = async () => {
      const { data } = await api.get("isProfileUpdated", {
        params: { cacheBuster: Date.now() }
      });
      if (!data?.updated) {
        setShowModal(true);
      }
    }
    isProfileUpdated();

    return () => {
      delete (window as any).TestEnableSubmissions;
    }
  }, [auth.email, disableSubmissions]);

  useEffect(() => {
    const fetchUserTickets = async () => {
      const tickets = await getUserTickets();
      setUserTickets(tickets);
    }
    fetchUserTickets(); // Call your async function to fetch the data when the component mounts
  }, []); // The empty array means this useEffect runs only once, when the component is first mounted

  const handleFridayTicketOpen = () => {
    window.open(userTickets[0], "_blank");
  };
  const handleFocusTicketOpen = () => {
    window.open(userTickets[1], "_blank");
  };


  const handleEssayClickOpen = () => {
    setEssayDialogOpen(true);
  };

  const handleEssayClose = () => {
    setEssayDialogOpen(false);
  };

  const Msg = () => {
    return (
      <div
        style={{
          margin: "1em",
          color: Colors.black,
          display: "flex",
          alignItems: "center",
        }}
      >
        REMEMBER TO UPLOAD A SUBMISSION UP HERE
        <Icon className={icon} fontSize={"large"}>
          <ArrowForwardIcon fontSize={"large"}></ArrowForwardIcon>
        </Icon>
      </div>
    );
  };

  const showToast = () => {
    toast(Msg);
  };

  
  const updateProfileModal = (
    <Modal 
      isShown={showModal} 
      titleText="Welcome back!" 
      bodyText="Before you continue, please take a moment to update your profile"
      buttonText="Go to profile"
      onClick={() => history.push("/profile")}
    />
  )

  return (
    <>
    {showModal && 
    updateProfileModal}
    <MuiThemeProvider theme={calendarTheme}>
      <Parallax blur={1} bgImage={"https://files.asymp.org/css/Assets/background.png"} bgImageAlt="the cat" strength={1000}>
        <div style={{ height: "100%" }}>
          <Container
            maxWidth="lg"
            style={{ padding: isMobile ? "0px" : "0px 16px 0px 16px" }}
          >
            <Grid container direction="column" alignItems="center">
              <Grid item>
              {userTickets.length > 0 ? 
          <IconButton onClick={() => {handleFridayTicketOpen()}}><Typography variant={"h5"} style={{
            margin: "10px", color: "black", background: "rgba(255,255,255,0.6)", borderRadius: "0.8rem", padding: "0.8rem",
          }}>FRIDAY TICKET</Typography></IconButton>
           : ""}
          {userTickets.length > 1 ? 
          <IconButton onClick={() => {handleFocusTicketOpen()}}><Typography variant={"h5"} style={{
            margin: "10px", color: "black", background: "rgba(255,255,255,0.6)", borderRadius: "0.8rem", padding: "0.8rem",
          }}>FOCUS TICKET</Typography></IconButton>
        
           : ""}
              </Grid>
              <Grid item>
                <Box pt="5em">
                  <img
                    src={logo}
                    style={{
                      width: "16em",
                      marginBottom: "2em",
                      maxWidth: isMobile ? "100%" : undefined,
                      alignSelf: "center",
                    }}
                  />
                </Box>
                <br />
              </Grid>
              <br />
              <Grid item xs={12}  style={{textAlign: "center"}}>
                <Container maxWidth="sm">
                  <Typography
                    color={"primary"}
                    style={{
                      fontFamily: "futura-pt",
                      textAlign: "center",
                      color: "white",
                      borderRadius: "0.8rem",
                    }}
                  >
                    {essayUploaded
                      ? "You have successfully uploaded your submission. You will receive more information as soon as we have reviewed all submissions. We will get back to you by mid-October at the latest. If you wish to change or edit your submission, track-priorities or A Day At-priorities you can do so below."
                      : ""}
                  </Typography>
                </Container>
                <br />
                {<Button
                  variant="contained"
                  color="primary"
                  onClick={handleEssayClickOpen}
                  size="large"
                  style={{borderRadius: "0.8rem", paddingLeft: "0.8rem", paddingRight: "0.8rem"}}
                  disabled={disableSubmissions}
                  >
                  <Typography variant="button" color="secondary" style={{fontFamily: "futura-pt"}}>
                  <p>{essayUploadedButtonText}</p>
                  {/* YOUR SUBMISSION IS BEING HANDLED... */}
                  </Typography>
                  
                </Button>}
              </Grid>

              <br />
              <br />

              <Grid item>
                <Typography variant="h4" style={headlineStyle} align="center">
                  THE AARHUS SYMPOSIUM EXPERIENCE
                </Typography>
              </Grid>
              <Box pt="5em"></Box>
            </Grid>

            <Timeline align={isMobile ? undefined : "alternate"}>
              {timeLineData.map((timeLineData: TimeLineData, index: number) => {
                if (isMobile) {
                  return (
                    <MobileTimelineItem
                      key={index}
                      timeLineData={timeLineData}
                      index={index}
                    />
                  );
                } else {
                  return (
                    <WebTimelineItem key={index} timeLineData={timeLineData} />
                  );
                }
              })}
              <TimelineItem className={isMobile ? mobile : undefined}>
                <TimelineSeparator>
                  <TimelineDot color="secondary" />
                </TimelineSeparator>
                <TimelineContent>
                  <Typography color={"secondary"} style={{fontFamily: "futura-pt"}}>The End</Typography>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </Container>
        </div>
      </Parallax>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
      />
      <Dialog
        fullScreen
        open={essayDialogOpen}
        onClose={handleEssayClose}
        style={{}}
        PaperProps={{ style: { backgroundImage: `url(${bgImage})` } }}
      >
        <ThemeProvider theme={calendarTheme}>
          <AppBar color="primary" className={appBar}>
            <Toolbar>
              <img src={logo} alt="Logo" height="50em" />
              <Typography variant="h6" className={title}></Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleEssayClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <EssayDialog></EssayDialog>
        </ThemeProvider>
      </Dialog>
    </MuiThemeProvider>
    </>
  );
};

export default ASTimeline;
