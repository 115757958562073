import { CSSProperties } from "react";
import { Colors } from "../../../constants";

const cardContent: CSSProperties = {
  display: "flex",
  alignItems: "center",
  alignContent: "center",
  flexDirection: "column",
};
const cardStyle: CSSProperties = { minWidth: "80%" };
const typography: CSSProperties = { color: Colors.orange, textAlign: "center" };
const IconDiv: CSSProperties = {
  flexDirection: "row",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

export { cardContent, cardStyle, typography, IconDiv };
