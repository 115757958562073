import { Button, ThemeProvider } from "@material-ui/core";
import { useState } from "react";
import { calendarTheme } from "../../styles/theme";
import EssayGraph from "./EssayGraph";
import PasswordPage from "./PasswordPage";
import UsersGraph from "./UsersGraph";

const Graphs = () => {
  const [showUsers, setShowUsers] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return isLoggedIn ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}>
      {showUsers ? <UsersGraph /> : <EssayGraph />}

      <ThemeProvider theme={calendarTheme}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setShowUsers(!showUsers)}>
            Switch To {showUsers ? "Essays" : "Users"}
          </Button>
        </div>
      </ThemeProvider>
    </div>
  ) : (
    <PasswordPage setLoggedIn={setIsLoggedIn}></PasswordPage>
  );
};

export default Graphs;
