import { useEffect, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { loginUser, useAuthDispatch, useAuthState } from "../../Context";
import ReCaptcha, { CaptchaProps } from "../../Recaptcha/Recaptcha";
import { innerFlexCenterWrapper } from "../../styles/globalStyles";
import { Colors } from "../../constants";
import HorizontalLine from "../../Components/HorizontalLine";
import logo from "../../../images/orange.png";
import { isMobile } from "react-device-detect";
import { UserType } from "../../interfaces";
import api from "../../api";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import EssayDialog from "../EssayDialog/EssayDialog";
import { Parallax } from "react-parallax";
import { headlineStyle } from "../Profile/styles";
import { Switch, useHistory } from "react-router-dom";
import {TextField} from "@material-ui/core";
import {
    MuiThemeProvider,
    makeStyles,
    ThemeProvider,
    Theme,
    createStyles,
  } from "@material-ui/core/styles";
  import { calendarTheme } from "../../styles/theme";
  import {
    AppBar,
    Box,
    Button,
    Container,
    Dialog,
    Grid,
    Icon,
    IconButton,
    Toolbar,
  } from "@material-ui/core";
  import bgImage from "../../images/Background2024.png";
import { FontStyle } from "@material-ui/core/styles/createTypography";

  export interface TimeLineData {
    buttonText: string;
    buttonLink: string;
    image: any;
    _id: string;
    title: string;
    description: string;
    date: Date | string;
    time: string;
  }


  const styles = makeStyles((theme) => ({
    mobile: {
      "&::before": {
        flex: 0,
      },
    },
    icon: {
      overflow: "visible",
      color: Colors.orange,
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    menuLink: { marginBottom: "2rem" },
  }));
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      appBar: {
        position: "relative",
      },
      title: {
        marginLeft: theme.spacing(2),
        flex: 1,
      },
      menuLink: { marginBottom: "2rem" },
    })
  );

  





export const GroupRegistration = () => {
    const [email, setEmail] = useState<string | null>();
    useEffect(() => {
    const networkCallback = async () => {
        try {
            const { data } = await api.get<UserType>("/getCurrentProfile");
            if (data){
                setEmail(data.email);
            }
        }
        catch(error){
            console.log("Test1");
        }
    }
    networkCallback();
    }, []);

    type Routes = "group"; //indsat groupregistration (Genbrug)

    const history = useHistory();

    const handleNavigation = (route: Routes | null) => {
        //if (route === "UPLOAD") handleEssayClickOpen();
        if (route === "group") history.push("/group");
      };

    const [Group, setGroup] = useState(false);

    const handleGroupCreated = () => {
        setGroup(true);
        //indsæt/post en gruppe her
        handleNavigation("group")
      };

      const handleAddMember = () => {
        setGroup(true);
        //indsæt/post en gruppe her
        
      };

    
    return (
    
    <MuiThemeProvider theme={calendarTheme}>
        <Parallax blur={1} bgImage={bgImage} bgImageAlt="the cat" strength={1000}>
            <Typography variant="h4" style={headlineStyle} align="center">
                SELECT THE PRIORITY FOR YOUR GROUP
            </Typography>
            <br />
            <Grid container justify="center">
            
            </Grid>
           
            <Typography variant="h5" style={headlineStyle} align="center">
                MEMBERS 
            </Typography>
            <Typography variant="h6" style={headlineStyle} align="center">
                Her indsættes medlemmer fra api get-kald
            </Typography>

            <br/>
            <Typography variant="h5" style={headlineStyle} align="center">
                TRACK PRIORITETER 
            </Typography>
            <Grid container justify="center">
            <TextField id="outlined-basic" label="1. PRIORITY" variant="outlined" />
            <TextField id="outlined-basic" label="2. PRIORITY" variant="outlined" />
            <TextField id="outlined-basic" label="3. PRIORITY" variant="outlined" />
            </Grid>
           
            <br />
            <br />
            <br />
            <Typography variant="h5" style={headlineStyle} align="center">
                ADD GROUP MEMBER 
            </Typography>
            <Grid container justify="center">
            <TextField id="email" label="ADD MEMBER" variant="outlined" />
            <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddMember}
                  size="large"
                  
                >
                    INVITE
            </Button>
            </Grid>
            <br />
            <br />
            <br />

            <Grid container justify="center">
            <Button
                  variant="contained"
                  color="primary"
                  onClick={handleGroupCreated}
                  size="large"
                  
                >
                    Submit Group
            </Button>
            </Grid>
          

            <br />
           
          
           
        </Parallax>

    </MuiThemeProvider>
    );
};

export default GroupRegistration;