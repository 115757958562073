import { useState } from "react";
import QrScanner from "../../QrScanner/QrScanner";
import OrganiserPasswordPage from "./PasswordPage";

const Checkin = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}>
      {isLoggedIn ? (
        <div>
      <p style={{
        height: "40vh",
        margin: "auto",
        width: "60%"
      }}>QR Checkin Tool</p>
      <QrScanner organiserName={username}></QrScanner>
      </div>
      ) : (
        <OrganiserPasswordPage
          setLoggedIn={setIsLoggedIn}
          username={username}
          setUsername={setUsername}></OrganiserPasswordPage>
      )}
    </div>
  );
};

export default Checkin;
