import React, { ReactNode, useState, useEffect } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { logout, useAuthDispatch, useAuthState } from "../Context";
import { Menu, Publish, Home } from "@material-ui/icons";
import { calendarTheme } from "../styles/theme";
import { Colors } from "../constants";
import EssayDialog from "../Pages/EssayDialog/EssayDialog";
import { Switch, useHistory } from "react-router-dom";
import AppRoutes from "../Router/AppRoutes";
import { routes } from "../Router/Routes";
import { Box, ClickAwayListener, Link } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import logo from "../images/logo.png";
import { isBeforeCL } from "./isBeforeCL";

type Routes = "HOME" | "UPLOAD" | "TRACKS" | "PROFILE" | "GROUP" | "LOGOUT" | "FAQ"; //indsat group

const essayTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const menuTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    menuLink: { marginBottom: "2rem" },
    rightScrollPaper: {
      alignItems: "flex-end",
      position: "absolute",
      right: "0px",
    },
    rightPaperScrollBody: {},
  })
);

const NavigationBar = () => {
  const { title, appBar, menuLink } = useStyles();
  const [essayDialogOpen, setEssayDialogOpen] = useState(false);
  const [menuDialogOpen, setMenuDialogOpen] = useState(false);

  const history = useHistory();
  const dispatch = useAuthDispatch(); // read dispatch method from context
  const classes = useStyles();

  

  const handleLogout = () => {
    logout(dispatch); //call the logout action
    history.push("/"); //navigate to logout page on logout
    window.location.reload();
  };

  const handleEssayClickOpen = () => {
    setEssayDialogOpen(true);
  };

  const handleEssayClose = () => {
    setEssayDialogOpen(false);
  };

  const handleMenuClickOpen = () => {
    setMenuDialogOpen(true);
  };

  const handleMenuClose = () => {
    setMenuDialogOpen(false);
  };


  const handleNavigation = (route: Routes | null) => {
    handleMenuClose();
    if (route === "HOME") history.push("/");
    if (route === "UPLOAD") handleEssayClickOpen();

    if (route === "TRACKS")
      window.open("https://www.aarhus-symposium.org/theme-announcement").focus();
    if (route === "PROFILE") history.push("/profile");
    if (route === "LOGOUT") handleLogout();
    if (route === "GROUP") history.push("/group");
    if (route === "FAQ")
      window.open("https://www.aarhus-symposium.org/faq").focus();
  };

  const { email } = useAuthState();

  return (
    <div
      id={"test1"}
      style={{
        width: "100%",
        flexDirection: isMobile ? "column" : "row",
        // flex: 1,
      }}
    >

      {isMobile && email ?
          <div
            style={{
              position: "fixed",
              right: 0,
              zIndex: 10
            }}
          >
          <IconButton
            onClick={handleMenuClickOpen}
          >
            <Menu fontSize="large" style={{
              margin: "10px"
            }}></Menu>
          </IconButton></div>: (email ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: isMobile ? "space-evenly" : "normal",
            position: isMobile ? "fixed" : "relative",
            height: "80px",
            width: isMobile ? "auto" : "auto",
            backgroundColor: isMobile ? "rgba(0, 0, 0, 0.5)" : "#ffffff",
            right: "0px",
          }}
        >
          <IconButton
            onClick={() => {handleNavigation("HOME")}}
          >
            <Home fontSize="large" style={{
              margin: "10px"
            }}></Home>
          </IconButton>
          <IconButton
            onClick={() => {handleNavigation("TRACKS")}}
          >
            <Typography variant={"h5"} style={{
              margin: "10px"
            }}>THEME 2024</Typography>
          </IconButton>
          <IconButton
            onClick={() => {handleNavigation("PROFILE")}}
          >
            <Typography variant={"h5"} style={{
              margin: "10px"
            }}>PROFILE</Typography>
          </IconButton>
          <IconButton
            onClick={() => {handleNavigation("GROUP")}}
          >
            <Typography variant={"h5"}>GROUP</Typography>
          </IconButton>
          <IconButton
            onClick={() => {handleNavigation("FAQ")}}
          >
            <Typography variant={"h5"} style={{
              margin: "10px"
            }}>FAQ</Typography>
          </IconButton>
          <IconButton
            onClick={() => {handleNavigation("LOGOUT")}}
          >
            <Typography variant={"h5"} style={{
              margin: "10px"
            }}>LOGOUT</Typography>
          </IconButton>

          <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "auto"
              }}>
          {isMobile ? <></> : <Box style={{ minWidth: "3.5em" }}></Box>}
          {isMobile ? (
            <div
              style={{
                borderLeft: `solid 2px ${Colors.opaceBlack}`,
                marginTop: "0.25rem",
                marginBottom: "0.25rem",
              }}
            />
          ) : null}
          {

            <IconButton
              onClick={handleEssayClickOpen} disabled={isBeforeCL()}>
              <Publish fontSize="large"></Publish>
            </IconButton>}
            </div>
        </div>
      ) : null)}
      <Dialog
        open={essayDialogOpen}
        fullScreen
        onClose={handleEssayClose}
        style={{ backgroundColor: "transparent" }}
      >
        <ThemeProvider theme={calendarTheme}>
          <AppBar color="primary" className={appBar}>
            <Toolbar>
              <img src={logo} alt="Logo" height="50em" />
              <Typography variant="h6" className={title}></Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleEssayClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <EssayDialog></EssayDialog>
        </ThemeProvider>
      </Dialog>

      <div style={{ flexGrow: 1 }}>
        <Switch>
          {routes.map(({ path, component, isPrivate, exact }, index) => (
            <AppRoutes
              key={index}
              path={path}
              component={component}
              isPrivate={isPrivate}
              exact={exact}
            />
          ))}
        </Switch>
      </div>
      <Dialog
        open={menuDialogOpen}
        fullScreen
        onClose={handleMenuClose}
        TransitionComponent={menuTransition}
        maxWidth="sm"
        hideBackdrop
        classes={{
          scrollPaper: classes.rightScrollPaper,
          paperScrollBody: classes.rightPaperScrollBody,
        }}
        PaperProps={{
          style: {
            width: isMobile ? "100%" : "auto",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            backdropFilter: "blur(4px)",
          },
        }}
      >
        <ThemeProvider theme={calendarTheme}>
          <ClickAwayListener onClickAway={handleMenuClose}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
                paddingBottom: "4rem",
                paddingTop: "4rem",
                paddingLeft: "2rem",
                paddingRight: "2rem",
              }}
            >
              <Typography color="secondary" variant="h3">
                /MENU
              </Typography>
              <div>
                <Typography className={menuLink} color="secondary" variant="h4">
                  <Link
                    color="secondary"
                    href="#"
                    onClick={(event: React.SyntheticEvent) => {
                      event.preventDefault();
                      handleNavigation("HOME");
                    }}
                  >
                    HOME
                  </Link>
                </Typography>
                {<Typography className={menuLink} color="secondary" variant="h4">
                  <Link
                    color="secondary"
                    href="#"
                    onClick={(event: React.SyntheticEvent) => {
                      event.preventDefault();
                      handleNavigation("UPLOAD");
                    }}
                  >
                    UPLOAD / EDIT SUBMISSION
                  </Link>
                </Typography>}
                <Typography className={menuLink} color="secondary" variant="h4">
                  <Link
                    color="secondary"
                    href="#"
                    onClick={(event: React.SyntheticEvent) => {
                      event.preventDefault();
                      handleNavigation("TRACKS");
                    }}
                  >
                    TRACKS & SPEAKERS
                  </Link>
                </Typography>
                <Typography className={menuLink} color="secondary" variant="h4">
                  <Link
                    color="secondary"
                    href="#"
                    onClick={(event: React.SyntheticEvent) => {
                      event.preventDefault();
                      handleNavigation("PROFILE");
                    }}
                  >
                    PROFILE
                  </Link>
                </Typography>
                <Typography className={menuLink} color="secondary" variant="h4">
                  <Link
                    color="secondary"
                    href="#"
                    onClick={(event: React.SyntheticEvent) => {
                      event.preventDefault();
                      handleNavigation("GROUP");
                    }}
                  >
                    GROUP SIGNUP
                  </Link>
                </Typography>
                <Typography className={menuLink} color="secondary" variant="h4">
                  <Link
                    color="secondary"
                    href="#"
                    onClick={(event: React.SyntheticEvent) => {
                      event.preventDefault();
                      handleNavigation("FAQ");
                    }}
                  >
                    FAQ
                  </Link>
                </Typography>
                <Typography className={menuLink} color="secondary" variant="h4">
                  <Link
                    color="secondary"
                    href="#"
                    onClick={(event: React.SyntheticEvent) => {
                      event.preventDefault();
                      handleNavigation("LOGOUT");
                    }}
                  >
                    LOGOUT
                  </Link>
                </Typography>
              </div>
              <Typography color="secondary" variant="h4">
                <Link
                  color="secondary"
                  href="#"
                  onClick={(event: React.SyntheticEvent) => {
                    event.preventDefault();
                    handleNavigation(null);
                  }}
                >
                  {">"}
                </Link>
              </Typography>
            </div>
          </ClickAwayListener>
        </ThemeProvider>
      </Dialog>
    </div>
  );
};

export default NavigationBar;
