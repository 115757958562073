import { TextField } from "@material-ui/core";
import { Dispatch, SetStateAction } from "react";
import {
  GroupedDropDownOptions,
  DropDownOption,
  otherOption,
  RegisterPayload,
} from "../../Context/interfaces";
import Select from "react-select";
import { selectStyle } from "../../Pages/Auth/Register/styles";
import { educations } from "./educationData";

/** Helper function to generate education options */
export function getEducationOptions(): GroupedDropDownOptions[] {
  const educationOptions: DropDownOption[] = educations.map((education) => ({
    label: education,
    value: education,
  }));
  educationOptions.push(otherOption);

  const frequentOptions: DropDownOption[] = [
    { label: "Økonomi", value: "Økonomi" },
    { label: "Erhvervsøkonomi, HA almen", value: "Erhvervsøkonomi, HA almen" },
    {
      label: "Economics and Business Administration",
      value: "Economics and Business Administration",
    },
    { label: "Statskundskab", value: "Statskundskab" },
    {
      label: "Erhvervsøkonomi og erhvervsret, HA(jur.)",
      value: "Erhvervsøkonomi og erhvervsret, HA(jur.)",
    },
    { label: "Politik og økonomi", value: "Politik og økonomi" },
  ];

  const groupOptions: GroupedDropDownOptions[] = [
    { label: "Frequently chosen", options: frequentOptions },
    { label: "All educations", options: educationOptions },
  ];

  return groupOptions;
}

// Helper function to get the payload education
export function getSelectedEducation(
  study: string,
  educationOptions: DropDownOption[]
): DropDownOption | null {
  if (study === "") {
    return null;
  }

  let payloadEducation: DropDownOption = educationOptions.find(
    (edu) => edu.value === study
  );
  if (payloadEducation === undefined) {
    payloadEducation = otherOption;
  }
  return payloadEducation;
}

interface Props {
  selectedInstitutionOption: DropDownOption | null;
  selectedEducationOption: DropDownOption | null;
  groupedEducationOptions: GroupedDropDownOptions[];
  payloadStudy: string;
  setUserDataCallback: Dispatch<SetStateAction<RegisterPayload>>;
  setSelecetedEducationOption: Dispatch<SetStateAction<DropDownOption | null>>;
}
/**
 * this displays the correct version of the educationInputComponent
 * depending on the give information
 * */
const EducationInputComponent: React.FC<Props> = ({
  selectedEducationOption,
  groupedEducationOptions,
  payloadStudy,
  setUserDataCallback,
  setSelecetedEducationOption,
}) => {
  if (
    selectedEducationOption &&
    selectedEducationOption.value === otherOption.value
  ) {
    return (
      <div>
        <Select
          placeholder="Education"
          value={selectedEducationOption}
          styles={selectStyle}
          onChange={(e) => {
            setSelecetedEducationOption(e);
            let study = e.value;
            if (e.value === otherOption.value) {
              study = "";
            }
            setUserDataCallback((prev) => ({
              ...prev,
              study: study,
            }));
          }}
          options={groupedEducationOptions}
          isSearchable={true}
          noOptionsMessage={() => "Please select an institution first"}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "1rem",
            width: "100%",
          }}>
          <TextField
            placeholder="Please enter the name of your study"
            value={payloadStudy}
            onChange={({ target }) => {
              setUserDataCallback((prev) => ({
                ...prev,
                study: target.value,
              }));
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <Select
      placeholder="Education"
      value={selectedEducationOption}
      styles={selectStyle}
      onChange={(e) => {
        setSelecetedEducationOption(e);
        let study = e.value;
        if (e === otherOption) {
          study = "";
        }
        setUserDataCallback((prev) => ({
          ...prev,
          study: study,
        }));
      }}
      options={groupedEducationOptions}
      isSearchable={true}
      noOptionsMessage={() => "Please select an institution first"}
    />
  );
};

export default EducationInputComponent;
