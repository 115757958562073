import { Html5Qrcode, Html5QrcodeScanner } from "html5-qrcode";
import {
  QrcodeErrorCallback,
  QrcodeSuccessCallback,
} from "html5-qrcode/esm/core";
import React, { useCallback, useEffect, useState } from "react";

interface Props {
  qrCodeSuccessCallback: QrcodeSuccessCallback;
  qrCodeErrorCallback: QrcodeErrorCallback;
  fps: number;
  qrbox: number;
}

const Html5QrcodeScannerPlugin = (props: Props) => {
  const { fps, qrbox, qrCodeErrorCallback, qrCodeSuccessCallback } = props;
  let html5QrcodeScanner: Html5QrcodeScanner;

  const qrCallBack = useCallback((qrString: string) => {
    qrCodeSuccessCallback(qrString, null);
  }, []);

  useEffect(() => {
    // Suceess callback is required.

    if (!qrCodeSuccessCallback) {
      throw "qrCodeSuccessCallback is required callback.";
    }

    html5QrcodeScanner = new Html5QrcodeScanner(
      "qr-code-full-region",
      { fps, qrbox, disableFlip: true },
      false
    );
    html5QrcodeScanner.render(qrCallBack, qrCodeErrorCallback);
  }, []);

  return <div id={"qr-code-full-region"} />;
};

export default Html5QrcodeScannerPlugin;
