import { Typography } from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { innerFlexCenterWrapper } from "../../../../styles/globalStyles";
import { headlineStyle, useStyles } from "../styles";

const Page4 = () => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.push("/");
    }, 5000);
  }, []);

  return (
    <div>
      <div style={innerFlexCenterWrapper}>
        <Typography
          variant="h4"
          style={{ ...headlineStyle, ...{ alignSelf: "center" } }}>
          THANK YOU!
        </Typography>
        <Typography
          variant="h5"
          style={{ ...headlineStyle, ...{ alignSelf: "center" } }}>
          PLEASE CHECK YOUR EMAIL TO VERIFY YOUR ACCOUNT.
        </Typography>
        <Typography
          variant="h5"
          style={{ ...headlineStyle, ...{ alignSelf: "center" } }}>
          REMEMBER TO LOOK IN YOUR SPAM FOLDER
        </Typography>
        <Typography
          variant="h5"
          style={{ ...headlineStyle, ...{ alignSelf: "center" } }}>
          We will redirected you shortly...
        </Typography>
      </div>
    </div>
  );
};

export default Page4;
