import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import AppRouter from "./Router/AppRouter";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { createContext } from "react";
import io from "socket.io-client";

const socket = io(
  process.env.NODE_ENV === "development"
    ? "http://localhost:8080"
    : "https://www.signup.asymp.org"
);

export const SocketContext = createContext(socket);

ReactDOM.render(
  <React.StrictMode>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <AppRouter />
    </MuiPickersUtilsProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
