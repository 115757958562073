import { Button, TextField } from "@material-ui/core";
import { Dispatch, SetStateAction, useState } from "react";
import { textFieldStyle, useStyles } from "../Login/styles";
import { innerFlexCenterWrapper } from "../../../styles/globalStyles";
import json from "../../../emails.json";

interface Props {
  setLoggedIn: Dispatch<SetStateAction<boolean>>;
  setUsername: Dispatch<SetStateAction<string>>;
  username: string;
}

const OrganiserPasswordPage = (props: Props) => {
  const { setLoggedIn, setUsername, username } = props;
  const [password, setPassword] = useState("");

  const handleSubmit = async () => {
    if (json.emails.includes(username) && password === "tjekdemind")
      setLoggedIn(true);
  };

  const classes = useStyles();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}>
        <div style={innerFlexCenterWrapper}>
          <TextField
            style={textFieldStyle}
            value={username}
            type="email"
            label="Login"
            onChange={({ target }) => setUsername(target.value)}></TextField>
          <TextField
            style={textFieldStyle}
            label="Password"
            value={password}
            type="password"
            onChange={({ target }) => setPassword(target.value)}></TextField>
          <Button
            style={{ marginBottom: 30 }}
            className={classes.button}
            variant="contained"
            disableElevation
            fullWidth
            type="submit">
            LOGIN
          </Button>
        </div>
      </div>
    </form>
  );
};

export default OrganiserPasswordPage;
