import { useEffect, useState } from "react";
import { Colors } from "../../constants";
import { IGroup, IGroupResponse, UserType } from "../../interfaces";
import api from "../../api";
import Typography from "@material-ui/core/Typography";
import { Parallax } from "react-parallax";
import { headlineStyle, modalStyleText } from "../Profile/styles";
import { Switch, useHistory } from "react-router-dom";
import {
  Card,
  CardContent,
  Container,
  TextField,
} from "@material-ui/core";
import "./styles.css";
import Modal, { closeModal, openModal } from "./Modal";
import {
  MuiThemeProvider,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import { calendarTheme } from "../../styles/theme";
import { Button, Grid } from "@material-ui/core";
import baggrundImage from "../../images/Background2024.png";
import { defaultTracks, TrackType } from "../EssayDialog/Fallbacks";
import strapiAPI from "../../strapiAPI";
import Tracks from "../EssayDialog/Tracks/Tracks";
import waait from "waait";
import { stepStyle } from "../Auth/Register/styles";
import { isMobile } from "react-device-detect";
import TrackCard from "../EssayDialog/Tracks/TrackCard";

// Use this to enable and disable group operations
const disableGroupOperations: boolean = false;

export interface TimeLineData {
  buttonText: string;
  buttonLink: string;
  image: any;
  _id: string;
  title: string;
  description: string;
  date: Date | string;
  time: string;
}

const styles = makeStyles((theme) => ({
  mobile: {
    "&::before": {
      flex: 0,
    },
  },
  icon: {
    overflow: "visible",
    color: Colors.orange,
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  menuLink: { marginBottom: "2rem" },
  titleStyle: {
    textAlign: "center",
    margin: "1rem",
    fontWeight: "bold",
  },
  headerCenter: {
    textAlign: "center",
    margin: "1rem",
  },
  resetButton: {
    color: Colors.white,
    backgroundColor: Colors.orange,
    marginLeft: "1rem",
  },
}));
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    menuLink: { marginBottom: "2rem" },
  })
);

export const Group = () => {
  const [statusMessage, setStatusMessage] = useState<string>(
    "Default status message"
  );
  const [ownerName, setOwnerName] = useState<string>();
  const [memberEmail, setMemberEmail] = useState<string>();
  const [email, setEmail] = useState<string | null>();
  const [groupStatus, setGroupStatus] = useState<string>();
  const [group, setGroup] = useState<IGroup>();
  const [checkGroup, setCheckGroup] = useState<any>(false);
  const [memberToRemove, setMemberToRemove] = useState<[string, string]>();
  const { headerCenter, titleStyle } = styles();

  const getGroup = async () => {
    try {
      const payload = { email };
      if (email) {
        const { data } = await api.post<IGroupResponse>("/getGroup", payload);
        const namePayload = { id: data.group.owner };

        var nameData;
        try {
          nameData = await api.post(
            "/getUserName?id=" + namePayload.id.toString()
          );
          setOwnerName(nameData.data["name"]);
        } catch (error) {}
        setGroupStatus(data.groupStatus);
        setGroup(data.group);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const networkCallback = async () => {
      try {

        getTracks();
        const { data } = await api.get<UserType>("/getCurrentProfile");
        if (data) {
          setEmail(data.email);
        }
        getGroup();
      } catch (error) {
      }
    };
    networkCallback();
  }, []);

  useEffect(() => {
    getGroup();
  }, [email]);

  useEffect(() => {
    getGroup();
  }, [checkGroup]);

  type Routes = "groupRegistration" | "groupOf" | "profile"; //indsat groupregistration (Genbrug)
  // type Routes = "groupOf"; //indsat groupregistration (Genbrug)

  const history = useHistory();

  function generatePriorityList(
    priorities: number[],
    tracks: TrackType[]
  ): string[] {
    let prioList: string[] = [];
    priorities.forEach(function (value, index) {
      if (value <= 0) {
        return;
      }
      prioList[value] = tracks[index].title;
    });
    return prioList;
  }

  function renderGroupMemberList(
    members: [string, string][]
  ): import("react").ReactNode {
    return (
      <div
        style={{
          margin: "auto",
          display: "flex",
          textAlign: "center",
          width: isMobile ? "65%" : "40%",
          borderRadius: "5px",
          justifyContent: "center",
          color: "white",
          background: "rgba(103, 103, 103, 0.5)",
        }}>
        <ol
          style={{
            border: "5px",
            width: "100%",
            margin: "0",
            padding: "0",
          }}>
          {members.map(function (member, index) {
            const pid = `pid_${index}`;
            return (
              <li
                className="groupList"
                style={{
                  font: "200 20px/1.5 Helvetica, Verdana, sans-serif",
                  borderBottom:
                    index === members.length - 1 ? "none" : "1px solid",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  padding: "5px",
                  WebkitBorderBefore: "prio",
                }}>
                <p
                  id={pid}
                  style={{
                    flex: "0 0 auto",
                    left: "50%",
                    position: "absolute",
                    transform: "translateX(-50%) translateY(-60%)",
                  }}>{`${member[1]}`}</p>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={disableGroupOperations}
                  onClick={() => {
                    setMemberToRemove(member);
                    setMemberEmail(member[1]);
                    openModal("remove");
                  }}
                  size="small"
                  style={{
                    marginLeft: "auto",
                  }}>
                  REMOVE
                </Button>
              </li>
            );
          })}
        </ol>
      </div>
    );
  }
  function renderList(members: string[]): import("react").ReactNode {
    return (
      <div
        style={{
          margin: "auto",
          display: "flex",
          textAlign: "center",
          width: isMobile ? "65%" : "40%",
          borderRadius: "5px",
          justifyContent: "center",
          color: "white",
          background: "rgba(103, 103, 103, 0.5)",
        }}>
        <ol
          style={{
            border: "5px",
            width: "100%",
            margin: "0",
            padding: "0",
          }}>
          {members.map(function (member, index) {
            return (
              <li
                className="groupList"
                style={{
                  font: "200 20px/1.5 Helvetica, Verdana, sans-serif",
                  borderBottom:
                    index === members.length - 1 ? "none" : "1px solid",
                  padding: "5px",
                }}>{`${member}`}</li>
            );
          })}
        </ol>
      </div>
    );
  }
  function getGroupMemberNames(group: IGroup): string[] {
    return group.members.map(function (tup, _) {
      return tup[1];
    });
  }

  function getMemberEmailFromName(group: IGroup, name: string): string {
    for (let i = 0; i < group.members.length; i++) {
      if (group.members[i][1] == name) return group.members[i][0];
    }
  }

  function getGroupMemberEmails(group: IGroup): string[] {
    return group.members.map(function (tup, _) {
      return tup[0];
    });
  }

  const handleDeleteGroup = async () => {
    const payload = {
      ownerEmail: email,
    };
    try {
      api
        .post("/deleteGroup", payload)
        .then((response) => setStatusMessage(response.data["message"]));
    } catch (error) {
      setStatusMessage(
        "Something went wrong during deletion. Please try again."
      );
      openModal("status");
    }
    await waait(300);
    setCheckGroup(!checkGroup);
  };

  const removeMember = async () => {
    if (!memberToRemove) {
      return;
    }
    console.log("Trying to remove member!");
    console.log(memberToRemove);
    const payload = {
      ownerEmail: email,
      memberEmail: memberToRemove[0],
    };
    try {
      api
        .post("/removeMember", payload)
        .then((response) => setStatusMessage(response.data["message"]));
    } catch (error) {
      setStatusMessage(
        "Something went wrong during removal of member. Please try again."
      );
      openModal("status");
    }
    await waait(300);
    setCheckGroup(!checkGroup);
  };

  const handleAddMember = () => {
    //indsæt/post en gruppe her
    let success = true;
    const inviteeEmail = (
      document.getElementById("inviteTextField") as HTMLInputElement
    ).value;
    const payload = {
      inviterEmail: email,
      inviteeEmail,
    };
    api.post("/inviteToGroup", payload).catch((error) => {
      success = false;
      setStatusMessage(error.response.data["error"]["message"]);
    });
    if (success) {
      setStatusMessage("Invitation sent!");
    }
  };

  const handleLeaveGroup = async () => {
    //indsæt/post en gruppe her
    const payload = {
      memberEmail: email,
    };

    try {
      api
        .post("/leaveGroup", payload)
        .then((response) => console.log(response))
        .catch((error) => console.log("HTTP error: " + JSON.stringify(error)));
      await waait(300);
      setCheckGroup(!checkGroup);
    } catch (error) {
      console.log("HTTP error: " + JSON.stringify(error));
    }
  };

  const [tracks, setTracks] = useState<TrackType[]>(defaultTracks);
  const [focus, setFocus] = useState<boolean>(null);
  const getTracks = async () => {
    const tracks = (await strapiAPI.get<TrackType[]>("/tracks"));
    setTracks(tracks.data.slice(0,4));
    console.log(tracks.data.slice(0,4));
  };
  const resetTracks = () =>
    setTracks((tracks) => tracks.map((track) => ({ ...track, priority: 0 })));
  const handleCreateGroup = async () => {
    var priorities: number[] = tracks.map((track) => {
      return track["priority"];
    });

    const payload = {
      owner: email,
      trackPriorities: priorities,
      focus: !!focus,
    };
    try {
      api
        .post("/createGroup", payload)
        .then((response) => console.log(response));
    } catch (error) {
      console.log("HTTP error: " + JSON.stringify(error));
    }
    resetTracks();
    closeModal("create");
    await waait(300);
    setCheckGroup(!checkGroup);
  };

  if (groupStatus === "Owner") {
    return (
      <MuiThemeProvider theme={calendarTheme}>
        <Parallax
          blur={1}
          bgImage={baggrundImage}
          bgImageAlt="the cat"
          strength={1000}>
          <Modal id={"delete"}>
            <div
              style={{
                borderRadius: "5px",
                backgroundColor: "white",
              }}>
              <br />
              <br />
              <Typography variant="h4" align="center">
                Are you sure you want to delete your group?
              </Typography>
              <br />
              <br />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}>
                <Grid container justify="center">
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginRight: "1rem" }}
                    disabled={disableGroupOperations}
                    onClick={() => {
                      handleDeleteGroup();
                      closeModal("delete");
                    }}
                    size="large">
                    YES
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      var modal = document.getElementsByClassName(
                        "modal"
                      ) as HTMLCollectionOf<HTMLElement>;
                      modal[0].style.display = "none";
                    }}
                    size="large">
                    NO
                  </Button>
                </Grid>
                <br />
                <br />
              </div>
            </div>
          </Modal>
          <Modal id={"remove"}>
            <div
              style={{
                borderRadius: "5px",
                backgroundColor: "white",
              }}>
              <br />
              <br />
              <Typography variant="h4" align="center">
                {`Are you sure you want to remove ${memberEmail} from your group?`}
              </Typography>
              <br />
              <br />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}>
                <Grid container justify="center">
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginRight: "1rem" }}
                    disabled={disableGroupOperations}
                    onClick={() => {
                      removeMember();
                      closeModal("remove");
                    }}
                    size="large">
                    YES
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      closeModal("remove");
                    }}
                    size="large">
                    NO
                  </Button>
                </Grid>
                <br />
                <br />
              </div>
            </div>
          </Modal>
          <Modal id={"status"}>
            <div>
              <Grid container justify="center">
                <Typography variant="h4">{statusMessage}</Typography>
              </Grid>
              {statusMessage === "Invitation sent!" ? (
                <div>
                  <Typography
                    variant="h6"
                    align="center"
                    style={{ fontStyle: "italic", marginTop: "2rem" }}>
                    OBS: The invitation might go into their spam folder
                  </Typography>
                </div>
              ) : (
                <></>
              )}
              <br />
            </div>
          </Modal>
          <br />
          <br />
          <br />
          <Typography variant="h4" style={headlineStyle} align="center">
            YOU ARE THE GROUP OWNER
          </Typography>

          <Typography variant="h5" style={headlineStyle} align="center">
            GROUP MEMBERS
          </Typography>
          {group !== undefined ? (
            group["members"].length > 0 ? (
              renderGroupMemberList(group.members)
            ) : (
              <div
                style={{
                  margin: "auto",
                  display: "flex",
                  textAlign: "center",
                  width: isMobile ? "65%" : "40%",
                  borderRadius: "5px",
                  justifyContent: "center",
                  color: "white",
                  background: "rgba(103, 103, 103, 0.5)",
                  font: "200 20px/1.5 Helvetica, Verdana, sans-serif",
                }}>
                <br />
                No members in your group yet.
                <br />
                Use the button below to invite your fellow students via e-mail.
                <br />
                <br />
              </div>
            )
          ) : (
            "LOADING"
          )}

          <br />
          <Typography variant="h5" style={headlineStyle} align="center">
            TRACK PRIORITIES
          </Typography>
          <Typography variant="h6" style={headlineStyle} align="center">
            {group !== undefined ? (
              generatePriorityList(group.trackPriorities, tracks).length > 0 ? (
                renderList(generatePriorityList(group.trackPriorities, tracks))
              ) : (
                <div
                  style={{
                    margin: "auto",
                    display: "flex",
                    textAlign: "center",
                    width: isMobile ? "65%" : "40%",
                    borderRadius: "5px",
                    justifyContent: "center",
                    color: "white",
                    background: "rgba(103, 103, 103, 0.5)",
                  }}>
                  <br />
                  You did not choose any track priorities
                  <br />
                  <br />
                </div>
              )
            ) : (
              "LOADING"
            )}
          </Typography>

          <br />
          <br />
          <Typography variant="h5" style={headlineStyle} align="center">
            ADD GROUP MEMBER BY E-MAIL
          </Typography>

          <Grid container justify="center">
            <TextField
              id="inviteTextField"
              label={
                group !== undefined
                  ? group.members?.length >= 2
                    ? "GROUP FULL"
                    : "ADD MEMBER"
                  : "ADD MEMBER"
              }
              variant="outlined"
              disabled={(group !== undefined ? group.members?.length >= 2 : true) || disableGroupOperations}
            />
            <Button
              variant="contained"
              color="secondary"
              disabled={(group !== undefined ? group.members?.length >= 2 : true) || disableGroupOperations}
              onClick={() => {
                handleAddMember();
                openModal("status");
                var textField = document.getElementById(
                  "inviteTextField"
                ) as HTMLInputElement;
                textField.value = null;
              }}
              size="large">
              INVITE
            </Button>
          </Grid>
          <br />
          <br />
          <br />
          <Grid container justify="center">
            <Button
              variant="contained"
              color="secondary"
              disabled={disableGroupOperations}
              onClick={() => {
                openModal("delete");
              }}
              size="large">
              DELETE GROUP
            </Button>
          </Grid>

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </Parallax>
      </MuiThemeProvider>
    );
  } else if (groupStatus === "Member") {
    return (
      <MuiThemeProvider theme={calendarTheme}>
        <Parallax
          blur={1}
          bgImage={baggrundImage}
          bgImageAlt="the cat"
          strength={1000}>
          <Typography variant="h4" style={headlineStyle} align="center">
            YOU ARE PART OF GROUP
          </Typography>
          <br />
          <Grid container justify="center"></Grid>

          <Typography variant="h5" style={headlineStyle} align="center">
            GROUP OWNER
          </Typography>
          {group !== undefined ? (
            <div
              style={{
                margin: "auto",
                display: "flex",
                textAlign: "center",
                width: isMobile ? "65%" : "40%",
                borderRadius: "5px",
                justifyContent: "center",
                color: "white",
                background: "rgba(103, 103, 103, 0.5)",
                font: "200 20px/1.5 Helvetica, Verdana, sans-serif",
              }}>
              <br />
              {ownerName}
              <br />
              <br />
            </div>
          ) : (
            "LOADING"
          )}

          <Typography variant="h5" style={headlineStyle} align="center">
            GROUP MEMBERS
          </Typography>
          {group !== undefined ? (
            group["members"].length > 0 ? (
              renderList(getGroupMemberNames(group))
            ) : (
              <div
                style={{
                  margin: "auto",
                  display: "flex",
                  textAlign: "center",
                  width: isMobile ? "65%" : "40%",
                  borderRadius: "5px",
                  justifyContent: "center",
                  color: "white",
                  background: "rgba(103, 103, 103, 0.5)",
                }}>
                <br />
                Problem reading members from server
                <br />
                <br />
              </div>
            )
          ) : (
            "LOADING"
          )}

          <br />

          <Typography variant="h5" style={headlineStyle} align="center">
            TRACK PRIORITIES
          </Typography>
          <Typography variant="h6" style={headlineStyle} align="center">
            {group !== undefined ? (
              generatePriorityList(group.trackPriorities, tracks).length > 0 ? (
                renderList(generatePriorityList(group.trackPriorities, tracks))
              ) : (
                <div
                  style={{
                    margin: "auto",
                    display: "flex",
                    textAlign: "center",
                    width: isMobile ? "65%" : "40%",
                    borderRadius: "5px",
                    justifyContent: "center",
                    color: "white",
                    background: "rgba(103, 103, 103, 0.5)",
                  }}>
                  <br />
                  The group owner did not choose any track priorities
                  <br />
                  <br />
                </div>
              )
            ) : (
              "LOADING"
            )}
          </Typography>

          <br />
          <Typography variant="h5" style={headlineStyle} align="center">
            FORLAD GRUPPE
          </Typography>
          <Grid container justify="center">
            <Button
              variant="contained"
              color="secondary"
              disabled={disableGroupOperations}
              onClick={handleLeaveGroup}
              size="large">
              LEAVE GROUP
            </Button>
          </Grid>
          <br />
          <br />
          <br />
          <br />
        </Parallax>
      </MuiThemeProvider>
    );
  } else if (groupStatus === "NotInGroup") {
    return (
      <MuiThemeProvider theme={calendarTheme}>
        <Parallax
          blur={1}
          bgImage={baggrundImage}
          bgImageAlt="the cat"
          strength={1000}>
          <Modal id={"create"}>
            <Card style={{ padding: isMobile ? "" : "2rem" }}>
              <Typography
                variant={"h4"}
                className={headerCenter}
                color={"primary"}>
                ARE YOU INTERESTED IN PARTICIPATING
              </Typography>
              <Typography
                variant={"h4"}
                className={headerCenter}
                color={"primary"}>
                IN AARHUS SYMPOSIUM FOCUS 2024?
              </Typography>
              <Container maxWidth="sm">
                <Typography
                  className={headerCenter}
                  color={"primary"}
                  style={{ fontFamily: "futura-pt" }}>
                  {
                    "Aarhus Symposium Focus is your chance to experience more of Aarhus Symposium. At Aarhus Symposium Focus, you will have a unique opportunity to experience one competent speaker addressing this year’s theme, Envisioning The New Era. "
                  }
                  <br></br>
                  {
                    "Are you uncertain of going alone? If you have registered as a group, you will also have the opportunity to experience the Aarhus Symposium Focus together. The event takes place on Monday, October 28, 2024."
                  }
                </Typography>
                <video
                  width="100%"
                  controls
                  src="../../../videos/Focus2023.mp4">
                  Your browser does not support the video tag.
                </video>
              </Container>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    margin: "2em",
                  }}>
                  <TrackCard
                    onPress={() => setFocus(false)}
                    marked={focus === false}
                    title=" "
                    text="">
                    <div style={{ paddingTop: "30%" }}>
                      <Typography
                        className={titleStyle}
                        variant="h5"
                        color="primary">
                        NO
                      </Typography>
                    </div>
                  </TrackCard>

                  <TrackCard
                    onPress={() => setFocus(true)}
                    marked={focus === true}
                    title=" "
                    text="">
                    <div style={{ paddingTop: "30%" }}>
                      <Typography
                        className={titleStyle}
                        variant="h5"
                        color="primary">
                        YES
                      </Typography>
                    </div>
                  </TrackCard>
                </div>
              </CardContent>
            </Card>
            <Grid container justify="center">
              <Tracks
                resetValues={resetTracks}
                tracks={tracks}
                setPriority={setTracks}
                nextPageCallback={null}
                handleCreateGroup={handleCreateGroup}
                focusChoice={focus}
                text={`
                NOTE: The track priorities are final and cannot be changed.
                `}
                title={
                  "PLEASE SELECT TRACK PRIORITIES FOR THE ENTIRE GROUP"
                }></Tracks>

              <div
                style={{
                  alignSelf: "center",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "2em",
                  marginBottom: "2em",
                }}></div>
            </Grid>
          </Modal>

          <br />
          <br />
          <br />
          <br />

          <Typography variant="h4" style={headlineStyle} align="center">
            YOU ARE NOT PART OF A GROUP
          </Typography>

          <div
            style={{
              margin: "auto",
              display: "flex",
              textAlign: "center",
              width: "60%",
              justifyContent: "center",
              color: "white",
            }}>
            <Typography variant="h6" style={headlineStyle} align="center">
              A group sign-up allows a maximum of three people to choose the
              same track priorities. This means that the members of the group
              will get the opportunity to experience the same track at this
              year's Aarhus Symposium on November 1, 2024. NOTE: The group
              sign-up will close on september 24, 2024, at 23:59
              <Typography variant="h5" style={headlineStyle} align="center">
                Each member of the group must still upload their own
                contribution to the Aarhus Symposium Challenge.
              </Typography>
            </Typography>
          </div>
          <Typography variant="h5" style={headlineStyle} align="center">
            TO SIGN UP AS A GROUP, PLEASE USE THE BUTTON BELOW.
          </Typography>
          <br />
          <br />
          <Grid container justify="center">
            <Button
              variant="contained"
              color="secondary"
              disabled={disableGroupOperations}
              onClick={() => {
                getTracks();
                openModal("create");
              }}
              size="large">
              Create a Group
            </Button>
          </Grid>
          <br />
          <br />
          <Typography variant="h4" style={headlineStyle} align="center">
            WAITING FOR AN INVITE?
          </Typography>
          <Typography variant="h5" style={stepStyle} align="center">
            CHECK YOUR E-MAIL
          </Typography>

          <br />
          <br />
          <div
            style={{
              margin: "auto",
              display: "flex",
              textAlign: "center",
              width: isMobile ? "65%" : "40%",
              borderRadius: "5px",
              justifyContent: "center",
              color: "white",
            }}>
            <Typography variant="h6" style={headlineStyle} align="center">
              Do you want to know more about the group sign-up, or do you have
              any questions? Please visit our FAQ in the menu.
            </Typography>
          </div>
        </Parallax>
      </MuiThemeProvider>
    );
  }
  return (
    <MuiThemeProvider theme={calendarTheme}>
      <Parallax
        blur={1}
        bgImage={baggrundImage}
        bgImageAlt="the cat"
        strength={1000}>
        <Typography variant="h4" style={modalStyleText} align="center">
          Loading
        </Typography>
      </Parallax>
    </MuiThemeProvider>
  );
};

export default Group;
