import { AxiosError } from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../api";
import waait from "waait";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, TextField, Typography } from "@material-ui/core";
import { innerFlexCenterWrapper } from "../../../styles/globalStyles";
import { textFieldStyle, useStyles } from "../Login/styles";
import { isMobile } from "react-device-detect";
import logo from "../../../images/orange.png";

export const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();

  const showToast = () => {
    toast.info('"Succes! Redirecting to login...', {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setError("");

    if (password !== confirmPassword) {
      setError("Passwords must match");
      return;
    }

    const pathArray = window.location.pathname.split("/");
    const token = pathArray[pathArray.length - 1];
    const path = "/reset/" + token;
    try {
      await api.post(path, {
        data: { password: password, confirmPassword: confirmPassword },
      });

      showToast();

      await waait(3000);

      history.push("/)");
    } catch (error) {
      const e: AxiosError<{ message: string }> = error;
      if (e.response) setError(e.response.data.message);
    }
  };

  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          display: isMobile ? "inline" : "flex",
          justifyContent: "center",
        }}>
        <div style={innerFlexCenterWrapper}>
          <img
            src={logo}
            style={{
              marginBottom: "2em",
              maxWidth: isMobile ? "100%" : undefined,
              alignSelf: "center",
            }}
          />
          <Typography
            variant={"body1"}
            style={{
              alignSelf: "center",
              marginBottom: "20px",
            }}>
            Please enter your new password below
          </Typography>
          <TextField
            style={textFieldStyle}
            label="Password"
            value={password}
            type="password"
            onChange={({ target }) => {
              setError("");
              setPassword(target.value);
            }}></TextField>
          <TextField
            style={textFieldStyle}
            value={confirmPassword}
            label="Confirm password"
            type="password"
            onChange={({ target }) => {
              setError("");
              setConfirmPassword(target.value);
            }}></TextField>
          {error ? (
            <Typography
              variant={"caption"}
              style={{
                alignSelf: "center",
                marginBottom: "10px",
                color: "ButtonText",
              }}>
              {error}
            </Typography>
          ) : null}
          <Button
            className={classes.button}
            variant="contained"
            disableElevation
            fullWidth
            type="submit">
            FORGOT PASSWORD?
          </Button>
        </div>
      </div>

      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </form>
  );
};
