import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { CSSProperties } from "react";
import { Colors } from "../../../constants";
import "../../../index.css";

export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    button: {
      color: Colors.white,
      backgroundColor: Colors.orange,
    },
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
  });
});

export const textStyle: CSSProperties = { fontSize: 12 };

export const textFieldStyle: CSSProperties = {
  marginBottom: "2em",
  borderBottom: `1px solid ${Colors.opaceBlack}`,
  fontFamily: "futura-pt",
};

export const termsStyle: CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  fontFamily: "futura-pt",
};
