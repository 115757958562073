import { Button, TextField, Typography } from "@material-ui/core";
import { AxiosError } from "axios";
import React, { FormEvent, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import api from "../../api";
import HorizontalLine from "../../Components/HorizontalLine";
import { Colors } from "../../constants";
import { UserType } from "../../interfaces";
import { innerFlexCenterWrapper } from "../../styles/globalStyles";
import { textFieldStyle, useStyles } from "../Auth/Login/styles";
import waait from "waait";
import Baggrund2022 from "../../images/Baggrund2022.jpg";

import {
    MuiThemeProvider,
    makeStyles,
    ThemeProvider,
    Theme,
    createStyles,
} from "@material-ui/core/styles";
import { Parallax } from "react-parallax";
import { calendarTheme } from "../../styles/theme";

const AcceptGroup = () => {
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState<string>();

    useEffect(() => {
        const acceptGroup = async () => {
            const token = window.location.pathname.split("/").pop();
            const path = "/acceptGroupInvitation/" + token;
            try {
                await api.get(path);
                await waait(3000);
                history.push("/");
            } catch (error) {
                setErrorMessage(error.response.data.error.message);
                await waait(3000);
                history.push("/");
            }
        };
        acceptGroup();
    }, []);
    if (errorMessage){
        return(
            <div>
            <ThemeProvider theme={calendarTheme}>
                <div style={innerFlexCenterWrapper}>
                    <Typography variant="h5" style={{ textAlign: "center" }}>
                        {errorMessage}
                    </Typography>
                </div>
            </ThemeProvider>
        </div>
        );
    }
    return (
        <div>
            <ThemeProvider theme={calendarTheme}>
                <div style={innerFlexCenterWrapper}>
                    <Typography variant="h4" style={{ textAlign: "center" }}>
                        THANK YOU!
                    </Typography>
                    <Typography variant="h5" style={{ textAlign: "center" }}>
                        You accepted the group invitation. We will redirect you soon...
                    </Typography>
                </div>
            </ThemeProvider>
        </div>
    );
}

export default AcceptGroup;