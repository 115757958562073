import React from "react";
import "./App.css";
import { BrowserRouter, Switch } from "react-router-dom";
import { routes } from "./Routes";
import { AuthProvider } from "../Context";
import AppRoutes from "./AppRoutes";
import { ThemeProvider } from "@material-ui/styles";
import { appTheme } from "../styles/theme";
import NavigationBar from "../Components/NavigationBar";
import { QueryClient, QueryClientProvider } from "react-query";

const AppRouter = (): JSX.Element => {
  const client = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <>
      <QueryClientProvider client={client}>
        <BrowserRouter>
          <AuthProvider>
            <ThemeProvider theme={appTheme}>
              <div className="fill-window">
                <NavigationBar></NavigationBar>
              </div>
            </ThemeProvider>
          </AuthProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </>
  );
};

export default AppRouter;
