import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors } from "../../../constants";
import "../../../index.css";

export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    title: {
      color: "#fff",
    },
    input: {
      width: "100%",
    },
    colorDefault: {
      backgroundColor: Colors.orange,
    },
    button: {
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",
      borderRadius: 24,
      color: Colors.orange,
    },
    loginButton: {
      paddingLeft: "4rem",
      paddingRight: "4rem",
      borderRadius: 24,
      color: Colors.orange,
    },
  });
});

export const headlineStyle: React.CSSProperties = {
  color: "#FFF",
  paddingTop: "2rem",
  paddingBottom: "1rem",
};

export const stepStyle: React.CSSProperties = {
  color: "#FFF",
};

export const TextFieldStyle: React.CSSProperties = {
  display: "flex",
  marginBottom: 20,
};

export const selectStyle = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected ? Colors.black : Colors.black,
    backgroundColor: state.isSelected ? Colors.opaqueOrange : Colors.white,
  }),

  control: (defaultStyles) => ({
    ...defaultStyles,
    backgroundColor: Colors.white,
    padding: "2px",
    border: "none",
    boxShadow: "none",
  }),
  singleValue: (defaultStyles) => ({ ...defaultStyles, color: Colors.black }),
};