import {
  Button,
  Direction,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { FormEvent, useState } from "react";
import { Dispatch, SetStateAction } from "react";
import Select from "react-select";
import {
  DropDownOption,
  Gender,
  GroupedDropDownOptions,
  RegisterPayload,
} from "../../../../Context/interfaces";
import { innerFlexCenterWrapper } from "../../../../styles/globalStyles";
import {
  headlineStyle,
  selectStyle,
  stepStyle,
  TextFieldStyle,
  useStyles,
} from "../styles";
import IOSSwitch from "../../../../Components/iOSSwitch";

import {
  getNationalityOptions,
  getSelectedNationalityOption,
} from "../../../../data/nationalities/nationalities";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/build/index.css";

interface Props {
  setUserDataCallback: Dispatch<SetStateAction<RegisterPayload>>;
  userData: RegisterPayload;
  onClick: (dir: Direction) => void;
}

const Page2 = (props: Props) => {
  const { setUserDataCallback, userData, onClick } = props;
  const {
    name,
    phone,
    nationality,
    postalCode,
    gender,
    vegetarian,
    glutenIntolerant,
    vegan,
  } = userData;

  const selectedNationalityFromPayload: DropDownOption =
    getSelectedNationalityOption(nationality);

  const [selectedNationality, setSelectedNationality] =
    useState<DropDownOption | null>(selectedNationalityFromPayload);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const buttonOneDisabled = !(
    name &&
    phone &&
    postalCode &&
    nationality &&
    gender
  );

  const handleOnNextClick = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isNaN(parseInt(phone))) {
      setErrorMessage("Phone number should be a number");
      return;
    }

    onClick("rtl");
  };

  const { button, input } = useStyles();

  const countryOptions: GroupedDropDownOptions[] = getNationalityOptions();

  return (
    <form onSubmit={handleOnNextClick}>
      <div style={innerFlexCenterWrapper}>
        <Typography variant="h6" style={stepStyle}>
          STEP 2 OF 3
        </Typography>
        <Typography variant="h4" style={headlineStyle}>
          TELL US MORE ABOUT YOURSELF
        </Typography>

        <Typography variant="h6" style={headlineStyle}>
          WHAT IS YOUR NATIONALITY?
        </Typography>

        <Select
          placeholder="Nationality"
          value={selectedNationality}
          styles={selectStyle}
          onChange={(e) => {
            setSelectedNationality(e);
            const nationality = e.value;
            setUserDataCallback((prev) => ({
              ...prev,
              nationality: nationality,
            }));
          }}
          options={countryOptions}
          isSearchable={true}
        />

        <div style={{ paddingTop: "1rem" }}>
          <TextField
            label="FULL NAME"
            style={TextFieldStyle}
            value={name}
            onChange={({ target }) =>
              setUserDataCallback((prev) => ({
                ...prev,
                name: target.value,
              }))
            }
            inputProps={{
              autoComplete: "name",
            }}></TextField>
        </div>

        <TextField
          label="POSTAL CODE"
          style={TextFieldStyle}
          value={postalCode}
          onChange={({ target }) =>
            setUserDataCallback((prev) => ({
              ...prev,
              postalCode: target.value,
            }))
          }
          inputProps={{
            autoComplete: "postal-code",
          }}></TextField>

        <TextField
          select
          className={input}
          value={gender || ""}
          label="GENDER"
          onChange={({ target }) =>
            setUserDataCallback((prev) => ({
              ...prev,
              gender: target.value as Gender,
            }))
          }
          inputProps={{
            autoComplete: "sex",
          }}>
          <MenuItem key={"man"} value={"man"}>
            Man
          </MenuItem>
          <MenuItem key={"woman"} value={"woman"}>
            Woman
          </MenuItem>
          <MenuItem key={"other"} value={"other"}>
            Other
          </MenuItem>
        </TextField>

        <PhoneInput
          defaultCountry="dk"
          value={phone}
          onChange={(phone) =>
            setUserDataCallback((prev) => ({ ...prev, phone }))
          }
          style={{ paddingTop: "2rem", paddingBottom: "1rem" }}
          inputProps={{
            autoComplete: "tel",
          }}
        />

        <Typography
          variant="h6"
          style={{
            color: "#FFF",
            paddingTop: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          ARE YOU VEGETARIAN?
          <IOSSwitch
            checked={vegetarian}
            onChange={({ target }) => {
              setUserDataCallback((prev) => ({
                ...prev,
                vegetarian: target.checked,
                vegan: target.checked ? false : prev.vegan,
              }));
            }}
          />
        </Typography>

        <Typography
          variant="h6"
          style={{
            color: "#FFF",
            paddingTop: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          ARE YOU VEGAN?
          <IOSSwitch
            checked={vegan}
            onChange={({ target }) => {
              setUserDataCallback((prev) => ({
                ...prev,
                vegan: target.checked,
                vegetarian: target.checked ? false : prev.vegetarian,
              }));
            }}
          />
        </Typography>

        <Typography
          variant="h6"
          style={{
            color: "#FFF",
            paddingTop: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          ARE YOU GLUTEN INTOLERANT?
          <IOSSwitch
            checked={glutenIntolerant}
            onChange={({ target }) => {
              setUserDataCallback((prev) => ({
                ...prev,
                glutenIntolerant: target.checked,
              }));
            }}
          />
        </Typography>
        <Typography
          variant="caption"
          style={{ color: "#FFF", paddingBottom: "1rem", paddingTop: "1rem" }}>
          This is used for food ordering at the event.
        </Typography>

        {errorMessage ? (
          <Typography
            variant={"h6"}
            color={"primary"}
            style={{
              textAlign: "center",
              marginBottom: "10px",
            }}>
            {errorMessage}
          </Typography>
        ) : null}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}>
          <Button
            className={button}
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => onClick("ltr")}>
            PREVIOUS
          </Button>
          <Button
            className={button}
            variant="contained"
            color="primary"
            disableElevation
            disabled={buttonOneDisabled}
            type="submit">
            NEXT
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Page2;
