import {
  mdiArrowLeftCircleOutline,
  mdiArrowRightCircleOutline,
  mdiArrowUpCircleOutline,
  mdiUpload,
} from "@mdi/js";
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Direction,
  IconButton,
  Link,
  makeStyles,
  Typography,
  Container,
  ThemeProvider,
  Button,
} from "@material-ui/core";
import { Colors } from "../../../constants";
import BottomDots from "../BottomDots/BottomDots";
import Icon from "@mdi/react";
import { cardContent, cardStyle, typography, IconDiv } from "./styles";

import { CSSProperties, Dispatch, SetStateAction, useState } from "react";
import { useEffect } from "react";
import api from "../../../api";
import { isMobile } from "react-device-detect";
import { calendarTheme } from "../../../styles/theme";
import { isBeforeCL } from "../../../Components/isBeforeCL";

// disable or enable essay upload here. This also shows a text to users, that it has been disabled.

interface Props {
  nextPageCallback: (direction: Direction) => void;
  setFile: Dispatch<SetStateAction<File>>;
  file: File;
  handleSubmit: () => Promise<void>;
  transportation: boolean;
  setTransportation: Dispatch<SetStateAction<boolean>>;
  essayLanguage: "Danish" | "English";
  setEssayLanguage: Dispatch<SetStateAction<"Danish" | "English">>;
}
const EssayUpload = (props: Props) => {
  const {
    nextPageCallback,
    setFile,
    file,
    handleSubmit,
    setTransportation,
    transportation,
    essayLanguage,
    setEssayLanguage,
  } = props;
  const [error, setError] = useState("");
  const essayBaseUrl = process.env.REACT_APP_DIGITALOCEANBASE;
  const [text, setText] = useState("");
  const [url, setUrl] = useState("");
  const [anonymity, setAnonymity] = useState(false);
  const [AiModel, setAiModel] = useState(false);
  const [disableEssayUpload, setDisableSubmissions] = useState<boolean>(true);
  const uploadButtonText: string = disableEssayUpload ? "Uploads of submissions are currently disabled" : "Upload Submission";


  const useStyles = makeStyles({
    input: {
      display: "none",
    },
  });

  useEffect(() => {
    setDisableSubmissions(isBeforeCL());
    const EnableSubmissions = (enable : boolean) => {
      setDisableSubmissions(!enable);
    }

    (window as any).TestEnableSubmissions = EnableSubmissions;

    const fetchEssay = async () => {
      const { data, status } = await api.get<string>("currentUserEssay", {});
      if (data && status == 200) {
        setUrl(data);
        setText(data.split("/")[2].split(".pdf")[0]);
      }
    };

    fetchEssay();
    
    return () => {
      delete (window as any).TestEnableSubmissions;
    }
  }, []);

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = event?.target?.files?.[0] || file;

    if (newFile?.type === "application/pdf") {
      setFile(newFile);
      setError("");
    } else {
      setError("File must be a pdf");
      setFile(undefined);
    }
  };

  const disabled =
    (!file?.name && !url) || !anonymity || !AiModel || disableEssayUpload;
  const rowstyle: CSSProperties = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "1rem",
  };
  const textStyle = { color: Colors.orange, paddingTop: "0.5rem" };

  const { input } = useStyles();
  return (
    <Card style={cardStyle}>
      <CardContent style={cardContent}>
        <input
          className={input}
          type="file"
          id="icon-button-essay"
          onChange={onFileChange}
          accept="application/pdf"
        />
        <label htmlFor="icon-button-essay">
          <IconButton color="primary" component="span">
            <Icon path={mdiUpload} color={Colors.orange} size={8} />
          </IconButton>
        </label>

        <div style={{ display: "column" }}>
          <Typography variant="h6" style={{ ...typography }}>
            {file?.name
              ? file.name
              : text
              ? "ALREADY UPLOADED"
              : "UPLOAD SUBMISSION AS PDF"}
          </Typography>

          {text && !file?.name ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link
                variant="h6"
                href={essayBaseUrl + url}
                style={{
                  ...typography,
                  marginBottom: "1rem",
                }}>
                {text + ".pdf"}
              </Link>
            </div>
          ) : null}
        </div>

        {text ? (
          <Typography variant="body1" style={typography}>
            {file
              ? ""
              : text
              ? "YOU CAN REPLACE THIS BY UPLOADING A NEW FILE"
              : ""}
          </Typography>
        ) : null}

        <div
          style={{
            marginTop: "1rem",
            display: "flex",
            flexDirection: "row",
          }}>
          <ThemeProvider theme={calendarTheme}>
            <Container
              maxWidth="sm"
              style={{
                border: `1px solid ${Colors.orange}`,
                borderRadius: 5,
                padding: "1rem",
              }}>
              <div style={rowstyle}>
                <Typography variant="body2" style={textStyle}>
                  What language have you chosen for your submission?
                </Typography>
                <div>
                  <Button
                    style={{ margin: "0.4rem" }}
                    variant={
                      essayLanguage === "English" ? "outlined" : "contained"
                    }
                    color="primary"
                    size={"small"}
                    onClick={() => setEssayLanguage("Danish")}>
                    <Typography
                      variant="button"
                      color={
                        essayLanguage === "Danish" ? "secondary" : "primary"
                      }>
                      DANISH
                    </Typography>
                  </Button>
                  <Button
                    style={{ margin: "0.4rem" }}
                    variant={
                      essayLanguage === "English" ? "contained" : "outlined"
                    }
                    color="primary"
                    size={"small"}
                    onClick={() => setEssayLanguage("English")}>
                    <Typography
                      variant="button"
                      color={
                        essayLanguage === "English" ? "secondary" : "primary"
                      }>
                      ENGLISH
                    </Typography>
                  </Button>
                </div>
              </div>
              <div style={rowstyle}>
                <Typography
                  variant="body2"
                  style={textStyle}
                  onClick={() => {
                    setTransportation(!transportation);
                  }}>
                  I will need transportation to attend Aarhus Symposium 2024
                </Typography>
                <div>
                  <Button
                    style={{ margin: "0.4rem" }}
                    variant={transportation ? "outlined" : "contained"}
                    color="primary"
                    size={"small"}
                    onClick={() => setTransportation(false)}>
                    <Typography
                      variant="button"
                      color={transportation ? "primary" : "secondary"}>
                      NO
                    </Typography>
                  </Button>
                  <Button
                    style={{ margin: "0.4rem" }}
                    variant={transportation ? "contained" : "outlined"}
                    color="primary"
                    size={"small"}
                    onClick={() => setTransportation(true)}>
                    <Typography
                      variant="button"
                      color={transportation ? "secondary" : "primary"}>
                      YES
                    </Typography>
                  </Button>
                </div>
              </div>

              <div style={rowstyle}>
                <Typography
                  variant="body2"
                  style={textStyle}
                  onClick={() => {
                    setAnonymity(!anonymity);
                  }}>
                  I confirm that my submission does not include name, address,
                  email or any other identifiable information
                </Typography>
                <Checkbox
                  checked={anonymity}
                  color="primary"
                  onChange={() => setAnonymity(!anonymity)}></Checkbox>
              </div>
              <div style={rowstyle}>
                <Typography
                  variant="body2"
                  style={textStyle}
                  onClick={() => {
                    setAnonymity(!anonymity);
                  }}>
                  Though technological innovation keeps surprising us with new
                  amazing possibilities to access information, we still believe
                  the power of the individual mind provides us with the
                  creativity, imagination and personal experience that enable us
                  to stand out in the crowd and connect with others on a deeper
                  level - today and tomorrow. Therefore, we trust you to shape
                  your submission without the help of AI language models like
                  ChatGPT. Essays that fail to meet this simple guideline will
                  not be accepted. By ticking the checkbox, I confirm that I did
                  not utilize any AI language model in the making of my
                  submission to the Aarhus Symposium 2024 Challenge
                </Typography>
                <Checkbox
                  checked={AiModel}
                  color="primary"
                  onChange={() => setAiModel(!AiModel)}></Checkbox>
              </div>
            </Container>
          </ThemeProvider>
        </div>
      </CardContent>
      <div style={IconDiv}>
        <IconButton
          onClick={() => {
            nextPageCallback("ltr");
          }}>
          <Icon
            path={mdiArrowLeftCircleOutline}
            color={Colors.orange}
            size={2}
          />
        </IconButton>
        <BottomDots marginLeft="8rem" marked={4}></BottomDots>
        <Box>
          <div
            style={{
              display: "relative",
              alignItems: "center",
              justifyContent: "flex-end",
            }}>
            {isMobile ? null : (
              <Box display="inline-block">
                <Typography
                  style={{
                    color: disableEssayUpload ? Colors.opaqueOrange : Colors.orange,
                    overflow: "visible",
                    whiteSpace: "nowrap",
                  }}>
                   {uploadButtonText}
                </Typography>
              </Box>
            )}
            <IconButton disabled={disabled} onClick={handleSubmit}>
              <Icon
                path={mdiArrowUpCircleOutline}
                color={disabled ? Colors.opaqueOrange : Colors.orange}
                size={2}
              />
            </IconButton>
          </div>
        </Box>
      </div>
    </Card>
  );
};
export default EssayUpload;
