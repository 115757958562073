import { DropDownOption, otherOption } from "../../Context/interfaces";
import { institutions } from "./institutionsData";


/** Helper function to generate university options */
export function getInstitutionOptions(): DropDownOption[] {
  const institutionOptions: DropDownOption[] = institutions.map(
    (institution) => ({
      label: institution,
      value: institution,
    })
  );
  institutionOptions.push(otherOption);
  return institutionOptions;
}

/** Helper function to get the payload university */
export function getSelectedInsitution(
  institution: string,
  universityOptions: DropDownOption[]
): DropDownOption | null {
  if (institution === "") {
    return null;
  }

  let payloadUniversity: DropDownOption = universityOptions.find(
    (uni) => uni.value === institution
  );
  if (payloadUniversity === undefined) {
    payloadUniversity = otherOption;
  }
  return payloadUniversity;
}
