import dayjs from "dayjs";
import { useEffect } from "react";
import {
  Redirect,
  Route,
  RouteProps,
  useHistory,
  useLocation,
} from "react-router-dom";
import { logout, useAuthDispatch, useAuthState } from "../Context";
import { IUser, JWT } from "../interfaces";
import jwt_decode from "jwt-decode";

const AppRoutes = ({
  component: Component,
  path,
  isPrivate,
  exact,
  ...rest
}: {
  component: any; // This is seriosuly not possible to typescript, Jesus...
  path: RouteProps["path"];
  isPrivate: boolean;
  exact: boolean;
}) => {
  const userDetails = useAuthState();
  const location = useLocation();
  const dispatch = useAuthDispatch(); // read dispatch method from context
  const history = useHistory();

  useEffect(() => {
    const checkJWTExpired = () => {
      const user: IUser = JSON.parse(localStorage.getItem("currentUser")!);
      if (user?.token) {
        const token_decoded: JWT = jwt_decode(user.token);
        if (dayjs(token_decoded.exp).add(1, "day") < dayjs()) {
          logout(dispatch);
          history.push("/");
        }
      }
    };
    checkJWTExpired();
  }, [location]);

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) =>
        isPrivate && !Boolean(userDetails.token) ? (
          <Redirect to={{ pathname: "/login" }} />
        ) : (
          <Component {...props} />
        )
      }
      {...rest}
    />
  );
};

export default AppRoutes;
