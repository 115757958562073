import { Button, Card, CardContent, Container, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Colors } from "../../../constants";
import { cardContent } from "../ConfirmationPage/styles";
import { cardStyle } from "../EssayUpload/styles";
import logo from "../../../images/orange2x.png";
import { useEffect } from "react";

const textStyle: React.CSSProperties = {
  color: Colors.black,
  paddingTop: "2rem",
  paddingBottom: "1rem",
  textAlign: "center",
};

const EssayThankYou = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.reload();
    }, 10000);
  }, []);

  return (
    <div style={{ marginTop: "2em" }}>
      <Card style={cardStyle}>
        <br />
        <br />

        <CardContent style={cardContent}>
          <Container maxWidth="sm">
            <Typography variant="h6" color={"textSecondary"} style={{ fontFamily: "Book Antiqua" }}>

              You have successfully submitted your submission. You will receive more information as soon as we have reviewed all submitted essays. We will get back to you by mid-October at the latest.

            </Typography>
          </Container>
        </CardContent>
        <br />
        <br />

      </Card>
    </div>
  );
};

export default EssayThankYou;
