import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@material-ui/lab";
import { Colors } from "../../constants";
import { TimeLineData } from "./Timeline";

interface Props {
  timeLineData: TimeLineData;
  index: number;
}

const styles = makeStyles((theme) => ({
  paper: {
    padding: "1rem", //før 6px 16px
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  connectorColor: { backgroundColor: Colors.white },
  timeline: {
    "&::before": {
      flex: 0,
    },
  },
}));
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const MobileTimelineItem = (props: Props) => {
  const { timeLineData, index } = props;
  const { connectorColor, timeline } = styles();
  if (!timeLineData.date) return null;
  return (
    <TimelineItem className={timeline} key={index}>
      <TimelineSeparator>
        <TimelineDot color="secondary" />
        <TimelineConnector className={connectorColor} />
      </TimelineSeparator>
      <TimelineContent>
        <Card>
          <CardActionArea disableRipple={true}>
            <CardMedia
              component="img"
              alt="Contemplative Reptile"
              height="20%"
              image={timeLineData.image?.formats?.small?.url}
              title="Contemplative Reptile"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {timeLineData.title?.toLocaleUpperCase()}
              </Typography>
              <Typography variant="body2" color="primary">
                {monthNames[
                  new Date(timeLineData.date).getMonth()
                ].toLocaleUpperCase() +
                  " " +
                  new Date(timeLineData.date).getDate() +
                  ", " +
                  new Date(timeLineData.date).getFullYear()}
              </Typography>
              <Typography variant="body2" color="primary">
                {timeLineData.time.slice(0, 5)}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {timeLineData.description}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary" href={timeLineData.buttonLink}>
              READ MORE
            </Button>
          </CardActions>
        </Card>
      </TimelineContent>
    </TimelineItem>
  );
};

export default MobileTimelineItem;
