import React, { useState } from "react";
import "./Modal.css";
import { Icon } from "@material-ui/core";
import { Close } from "@material-ui/icons";

interface Props {
  isShown: boolean;
  titleText: string;
  bodyText: string;
  buttonText: string;
  onClick: () => void;
}

const Modal = (props: Props) => {
  const {isShown, titleText, bodyText, buttonText, onClick} = props;
  const [showModal, setShowModal] = useState<boolean>(isShown);

  const handleClose = () => {
    setShowModal(false);
  };

  return showModal ? (
    <div className="modal-background">
      <div className="modal-content">
        <div className="modal-header">
          <h2>{titleText}</h2>
          <button className="modal-close" onClick={handleClose}>
            <Icon>
              <Close></Close>
            </Icon>
          </button>
        </div>
        <div className="modal-body">
          <p>{bodyText}</p>
        </div>
        <div className="modal-footer">
          <button className="modal-submit" onClick={onClick}>
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  ): null;
};

export default Modal;
