import { RouteProps } from "react-router-dom";
import { About } from "../Pages/About/About";
import { ForgotPassword } from "../Pages/Auth/ForgotPassword/ForgotPassword";
import Login from "../Pages/Auth/Login/Login";
import NotFound from "../Pages/Auth/NotFound/NotFound";
import Register from "../Pages/Auth/Register/Register";
import { ResetPassword } from "../Pages/Auth/ResetPassword/ResetPassword";
import ValidateUser from "../Pages/Auth/validateUser/validateUser";
import EssayReview from "../Pages/EssayReview/EssayReview";
import ASTimeline from "../Pages/Timeline/Timeline";
import ProfilePage from "../Pages/Profile/ProfilePage";
import Group from "../Pages/Group/Group"; //indsat
import GroupRegistration from "../Pages/Group/GroupRegistration"; //indsat
import AcceptGroup from "../Pages/Group/AcceptGroup"; //indsat
import GroupOf from "../Pages/Group/PartOfGroup"; //indsat
import EssayGraph from "../Pages/EssayGraph/EssayGraph";
import Graphs from "../Pages/EssayGraph/Graphs";
import Checkin from "../Pages/Auth/Checkin/Checkin";
import EssayReviewExternal from "../Pages/EssayReview/EssayReviewExternal";
//import { Group } from "@material-ui/icons";

interface AuthenticatedRouteProps extends RouteProps {
  isPrivate: boolean;
  exact: boolean;
}

const publicRoutes: AuthenticatedRouteProps[] = [
  {
    path: "/login",
    component: Login,
    isPrivate: false,
    exact: true,
  },
  {
    path: "/about",
    component: About,
    isPrivate: false,
    exact: true,
  },

  {
    path: "/register",
    component: Register,
    isPrivate: false,
    exact: true,
  },
  {
    path: "/resetPassword/:token",
    component: ResetPassword,
    isPrivate: false,
    exact: true,
  },
  {
    path: "/resetPassword",
    component: ForgotPassword,
    isPrivate: false,
    exact: true,
  },
  {
    path: "/validate/:token",
    component: ValidateUser,
    isPrivate: false,
    exact: true,
  },
  {
    path: "/essayGraph",
    component: Graphs,
    isPrivate: false,
    exact: true,
  },
  {
    path: "/essayReview/:id",
    component: EssayReview,
    isPrivate: false,
    exact: true,
  },
  {
    path: "/external/essayReview/:id",
    component: EssayReviewExternal,
    isPrivate: false,
    exact: true,
  },
  {
    path: "/checkin",
    component: Checkin,
    isPrivate: false,
    exact: true,
  },
];

const privateRoutes: AuthenticatedRouteProps[] = [
  {
    path: "/",
    component: ASTimeline,
    isPrivate: true,
    exact: true,
  },

  {
    path: "/profile",
    component: ProfilePage,
    isPrivate: true,
    exact: true,
  },
  { //indsat
    path: "/group",
    component: Group, //ret denne til den component jeg arbejder på
    isPrivate: false,
    exact: true,
  },
  { //indsat
    path: "/AcceptGroupInvitation/:token",
    component: AcceptGroup, //ret denne til den component jeg arbejder på
    isPrivate: false,
    exact: true,
  },
  { //indsat
    path: "/groupRegistration",
    component: GroupRegistration, //Når der trykkes på Create Group
    isPrivate: true,
    exact: true,
  },
  { //indsat
    path: "/groupOf",
    component: GroupOf, //Når der trykkes på Create Group
    isPrivate: true,
    exact: true,
  },
  {
    path: "/*",
    component: NotFound,
    isPrivate: true,
    exact: true,
  },
];

export const routes = publicRoutes.concat(privateRoutes);
